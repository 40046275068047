/**
 * Erweiterung des Bootstrap-Grids um Spalten mit halber Grösse.
 */

.col-xs-0\.5, .col-sm-0\.5, .col-md-0\.5, .col-lg-0\.5, .col-xs-1\.5, .col-sm-1\.5, .col-md-1\.5, .col-lg-1\.5, .col-xs-2\.5, .col-sm-2\.5, .col-md-2\.5, .col-lg-2\.5, .col-xs-3\.5, .col-sm-3\.5, .col-md-3\.5, .col-lg-3\.5, .col-xs-4\.5, .col-sm-4\.5, .col-md-4\.5, .col-lg-4\.5, .col-xs-5\.5, .col-sm-5\.5, .col-md-5\.5, .col-lg-5\.5, .col-xs-6\.5, .col-sm-6\.5, .col-md-6\.5, .col-lg-6\.5, .col-xs-7\.5, .col-sm-7\.5, .col-md-7\.5, .col-lg-7\.5, .col-xs-8\.5, .col-sm-8\.5, .col-md-8\.5, .col-lg-8\.5, .col-xs-9\.5, .col-sm-9\.5, .col-md-9\.5, .col-lg-9\.5, .col-xs-10\.5, .col-sm-10\.5, .col-md-10\.5, .col-lg-10\.5, .col-xs-11\.5, .col-sm-11\.5, .col-md-11\.5, .col-lg-11\.5 {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}
.col-xs-0\.5, .col-xs-1\.5, .col-xs-2\.5, .col-xs-3\.5, .col-xs-4\.5, .col-xs-5\.5, .col-xs-6\.5, .col-xs-7\.5, .col-xs-8\.5, .col-xs-9\.5, .col-xs-10\.5, .col-xs-11\.5 {
    float: left;
}

.col-xs-11\.5 {
    width: 95.83333333%;
}
.col-xs-10\.5 {
    width: 87.5%;
}
.col-xs-9\.5 {
    width: 79.16666667%;
}
.col-xs-8\.5 {
    width: 70.83333333%;
}
.col-xs-7\.5 {
    width: 62.5%;
}
.col-xs-6\.5 {
    width: 54.16666667%;
}
.col-xs-5\.5 {
    width: 45.83333333%;
}
.col-xs-4\.5 {
    width: 37.5%;
}
.col-xs-3\.5 {
    width: 29.16666667%;
}
.col-xs-2\.5 {
    width: 20.83333333%;
}
.col-xs-1\.5 {
    width: 12.5%;
}
.col-xs-0\.5 {
    width: 4.16666667%;
}

.col-xs-pull-11\.5 {
    right: 95.83333333%;
}
.col-xs-pull-10\.5 {
    right: 87.5%;
}
.col-xs-pull-9\.5 {
    right: 79.16666667%;
}
.col-xs-pull-8\.5 {
    right: 70.83333333%;
}
.col-xs-pull-7\.5 {
    right: 62.5%;
}
.col-xs-pull-6\.5 {
    right: 54.16666667%;
}
.col-xs-pull-5\.5 {
    right: 45.83333333%;
}
.col-xs-pull-4\.5 {
    right: 37.5%;
}
.col-xs-pull-3\.5 {
    right: 29.16666667%;
}
.col-xs-pull-2\.5 {
    right: 20.83333333%;
}
.col-xs-pull-1\.5 {
    right: 12.5%;
}
.col-xs-pull-0\.5 {
    right: 4.16666667%;
}

.col-xs-push-11\.5 {
    left: 95.83333333%;
}
.col-xs-push-10\.5 {
    left: 87.5%;
}
.col-xs-push-9\.5 {
    left: 79.16666667%;
}
.col-xs-push-8\.5 {
    left: 70.83333333%;
}
.col-xs-push-7\.5 {
    left: 62.5%;
}
.col-xs-push-6\.5 {
    left: 54.16666667%;
}
.col-xs-push-5\.5 {
    left: 45.83333333%;
}
.col-xs-push-4\.5 {
    left: 37.5%;
}
.col-xs-push-3\.5 {
    left: 29.16666667%;
}
.col-xs-push-2\.5 {
    left: 20.83333333%;
}
.col-xs-push-1\.5 {
    left: 12.5%;
}
.col-xs-push-0\.5 {
    left: 4.16666667%;
}

.col-xs-offset-11\.5 {
    margin-left: 95.83333333%;
}
.col-xs-offset-10\.5 {
    margin-left: 87.5%;
}
.col-xs-offset-9\.5 {
    margin-left: 79.16666667%;
}
.col-xs-offset-8\.5 {
    margin-left: 70.83333333%;
}
.col-xs-offset-7\.5 {
    margin-left: 62.5%;
}
.col-xs-offset-6\.5 {
    margin-left: 54.16666667%;
}
.col-xs-offset-5\.5 {
    margin-left: 45.83333333%;
}
.col-xs-offset-4\.5 {
    margin-left: 37.5%;
}
.col-xs-offset-3\.5 {
    margin-left: 29.16666667%;
}
.col-xs-offset-2\.5 {
    margin-left: 20.83333333%;
}
.col-xs-offset-1\.5 {
    margin-left: 12.5%;
}
.col-xs-offset-0\.5 {
    margin-left: 4.16666667%;
}

@media (min-width: 768px) {
    .col-sm-0\.5, .col-sm-1\.5, .col-sm-2\.5, .col-sm-3\.5, .col-sm-4\.5, .col-sm-5\.5, .col-sm-6\.5, .col-sm-7\.5, .col-sm-8\.5, .col-sm-9\.5, .col-sm-10\.5, .col-sm-11\.5 {
        float: left;
    }

    .col-sm-11\.5 {
        width: 95.83333333%;
    }
    .col-sm-10\.5 {
        width: 87.5%;
    }
    .col-sm-9\.5 {
        width: 79.16666667%;
    }
    .col-sm-8\.5 {
        width: 70.83333333%;
    }
    .col-sm-7\.5 {
        width: 62.5%;
    }
    .col-sm-6\.5 {
        width: 54.16666667%;
    }
    .col-sm-5\.5 {
        width: 45.83333333%;
    }
    .col-sm-4\.5 {
        width: 37.5%;
    }
    .col-sm-3\.5 {
        width: 29.16666667%;
    }
    .col-sm-2\.5 {
        width: 20.83333333%;
    }
    .col-sm-1\.5 {
        width: 12.5%;
    }
    .col-sm-0\.5 {
        width: 4.16666667%;
    }

    .col-sm-pull-11\.5 {
        right: 95.83333333%;
    }
    .col-sm-pull-10\.5 {
        right: 87.5%;
    }
    .col-sm-pull-9\.5 {
        right: 79.16666667%;
    }
    .col-sm-pull-8\.5 {
        right: 70.83333333%;
    }
    .col-sm-pull-7\.5 {
        right: 62.5%;
    }
    .col-sm-pull-6\.5 {
        right: 54.16666667%;
    }
    .col-sm-pull-5\.5 {
        right: 45.83333333%;
    }
    .col-sm-pull-4\.5 {
        right: 37.5%;
    }
    .col-sm-pull-3\.5 {
        right: 29.16666667%;
    }
    .col-sm-pull-2\.5 {
        right: 20.83333333%;
    }
    .col-sm-pull-1\.5 {
        right: 12.5%;
    }
    .col-sm-pull-0\.5 {
        right: 4.16666667%;
    }

    .col-sm-push-11\.5 {
        left: 95.83333333%;
    }
    .col-sm-push-10\.5 {
        left: 87.5%;
    }
    .col-sm-push-9\.5 {
        left: 79.16666667%;
    }
    .col-sm-push-8\.5 {
        left: 70.83333333%;
    }
    .col-sm-push-7\.5 {
        left: 62.5%;
    }
    .col-sm-push-6\.5 {
        left: 54.16666667%;
    }
    .col-sm-push-5\.5 {
        left: 45.83333333%;
    }
    .col-sm-push-4\.5 {
        left: 37.5%;
    }
    .col-sm-push-3\.5 {
        left: 29.16666667%;
    }
    .col-sm-push-2\.5 {
        left: 20.83333333%;
    }
    .col-sm-push-1\.5 {
        left: 12.5%;
    }
    .col-sm-push-0\.5 {
        left: 4.16666667%;
    }

    .col-sm-offset-11\.5 {
        margin-left: 95.83333333%;
    }
    .col-sm-offset-10\.5 {
        margin-left: 87.5%;
    }
    .col-sm-offset-9\.5 {
        margin-left: 79.16666667%;
    }
    .col-sm-offset-8\.5 {
        margin-left: 70.83333333%;
    }
    .col-sm-offset-7\.5 {
        margin-left: 62.5%;
    }
    .col-sm-offset-6\.5 {
        margin-left: 54.16666667%;
    }
    .col-sm-offset-5\.5 {
        margin-left: 45.83333333%;
    }
    .col-sm-offset-4\.5 {
        margin-left: 37.5%;
    }
    .col-sm-offset-3\.5 {
        margin-left: 29.16666667%;
    }
    .col-sm-offset-2\.5 {
        margin-left: 20.83333333%;
    }
    .col-sm-offset-1\.5 {
        margin-left: 12.5%;
    }
    .col-sm-offset-0\.5 {
        margin-left: 4.16666667%;
    }
}
@media (min-width: 992px) {
    .col-md-0\.5, .col-md-1\.5, .col-md-2\.5, .col-md-3\.5, .col-md-4\.5, .col-md-5\.5, .col-md-6\.5, .col-md-7\.5, .col-md-8\.5, .col-md-9\.5, .col-md-10\.5, .col-md-11\.5 {
        float: left;
    }

    .col-md-11\.5 {
        width: 95.83333333%;
    }
    .col-md-10\.5 {
        width: 87.5%;
    }
    .col-md-9\.5 {
        width: 79.16666667%;
    }
    .col-md-8\.5 {
        width: 70.83333333%;
    }
    .col-md-7\.5 {
        width: 62.5%;
    }
    .col-md-6\.5 {
        width: 54.16666667%;
    }
    .col-md-5\.5 {
        width: 45.83333333%;
    }
    .col-md-4\.5 {
        width: 37.5%;
    }
    .col-md-3\.5 {
        width: 29.16666667%;
    }
    .col-md-2\.5 {
        width: 20.83333333%;
    }
    .col-md-1\.5 {
        width: 12.5%;
    }
    .col-md-0\.5 {
        width: 4.16666667%;
    }

    .col-md-pull-11\.5 {
        right: 95.83333333%;
    }
    .col-md-pull-10\.5 {
        right: 87.5%;
    }
    .col-md-pull-9\.5 {
        right: 79.16666667%;
    }
    .col-md-pull-8\.5 {
        right: 70.83333333%;
    }
    .col-md-pull-7\.5 {
        right: 62.5%;
    }
    .col-md-pull-6\.5 {
        right: 54.16666667%;
    }
    .col-md-pull-5\.5 {
        right: 45.83333333%;
    }
    .col-md-pull-4\.5 {
        right: 37.5%;
    }
    .col-md-pull-3\.5 {
        right: 29.16666667%;
    }
    .col-md-pull-2\.5 {
        right: 20.83333333%;
    }
    .col-md-pull-1\.5 {
        right: 12.5%;
    }
    .col-md-pull-0\.5 {
        right: 4.16666667%;
    }

    .col-md-push-11\.5 {
        left: 95.83333333%;
    }
    .col-md-push-10\.5 {
        left: 87.5%;
    }
    .col-md-push-9\.5 {
        left: 79.16666667%;
    }
    .col-md-push-8\.5 {
        left: 70.83333333%;
    }
    .col-md-push-7\.5 {
        left: 62.5%;
    }
    .col-md-push-6\.5 {
        left: 54.16666667%;
    }
    .col-md-push-5\.5 {
        left: 45.83333333%;
    }
    .col-md-push-4\.5 {
        left: 37.5%;
    }
    .col-md-push-3\.5 {
        left: 29.16666667%;
    }
    .col-md-push-2\.5 {
        left: 20.83333333%;
    }
    .col-md-push-1\.5 {
        left: 12.5%;
    }
    .col-md-push-0\.5 {
        left: 4.16666667%;
    }

    .col-md-offset-11\.5 {
        margin-left: 95.83333333%;
    }
    .col-md-offset-10\.5 {
        margin-left: 87.5%;
    }
    .col-md-offset-9\.5 {
        margin-left: 79.16666667%;
    }
    .col-md-offset-8\.5 {
        margin-left: 70.83333333%;
    }
    .col-md-offset-7\.5 {
        margin-left: 62.5%;
    }
    .col-md-offset-6\.5 {
        margin-left: 54.16666667%;
    }
    .col-md-offset-5\.5 {
        margin-left: 45.83333333%;
    }
    .col-md-offset-4\.5 {
        margin-left: 37.5%;
    }
    .col-md-offset-3\.5 {
        margin-left: 29.16666667%;
    }
    .col-md-offset-2\.5 {
        margin-left: 20.83333333%;
    }
    .col-md-offset-1\.5 {
        margin-left: 12.5%;
    }
    .col-md-offset-0\.5 {
        margin-left: 4.16666667%;
    }
}
@media (min-width: 1200px) {
    .col-lg-0\.5, .col-lg-1\.5, .col-lg-2\.5, .col-lg-3\.5, .col-lg-4\.5, .col-lg-5\.5, .col-lg-6\.5, .col-lg-7\.5, .col-lg-8\.5, .col-lg-9\.5, .col-lg-10\.5, .col-lg-11\.5 {
        float: left;
    }

    .col-lg-11\.5 {
        width: 95.83333333%;
    }
    .col-lg-10\.5 {
        width: 87.5%;
    }
    .col-lg-9\.5 {
        width: 79.16666667%;
    }
    .col-lg-8\.5 {
        width: 70.83333333%;
    }
    .col-lg-7\.5 {
        width: 62.5%;
    }
    .col-lg-6\.5 {
        width: 54.16666667%;
    }
    .col-lg-5\.5 {
        width: 45.83333333%;
    }
    .col-lg-4\.5 {
        width: 37.5%;
    }
    .col-lg-3\.5 {
        width: 29.16666667%;
    }
    .col-lg-2\.5 {
        width: 20.83333333%;
    }
    .col-lg-1\.5 {
        width: 12.5%;
    }
    .col-lg-0\.5 {
        width: 4.16666667%;
    }

    .col-lg-pull-11\.5 {
        right: 95.83333333%;
    }
    .col-lg-pull-10\.5 {
        right: 87.5%;
    }
    .col-lg-pull-9\.5 {
        right: 79.16666667%;
    }
    .col-lg-pull-8\.5 {
        right: 70.83333333%;
    }
    .col-lg-pull-7\.5 {
        right: 62.5%;
    }
    .col-lg-pull-6\.5 {
        right: 54.16666667%;
    }
    .col-lg-pull-5\.5 {
        right: 45.83333333%;
    }
    .col-lg-pull-4\.5 {
        right: 37.5%;
    }
    .col-lg-pull-3\.5 {
        right: 29.16666667%;
    }
    .col-lg-pull-2\.5 {
        right: 20.83333333%;
    }
    .col-lg-pull-1\.5 {
        right: 12.5%;
    }
    .col-lg-pull-0\.5 {
        right: 4.16666667%;
    }

    .col-lg-push-11\.5 {
        left: 95.83333333%;
    }
    .col-lg-push-10\.5 {
        left: 87.5%;
    }
    .col-lg-push-9\.5 {
        left: 79.16666667%;
    }
    .col-lg-push-8\.5 {
        left: 70.83333333%;
    }
    .col-lg-push-7\.5 {
        left: 62.5%;
    }
    .col-lg-push-6\.5 {
        left: 54.16666667%;
    }
    .col-lg-push-5\.5 {
        left: 45.83333333%;
    }
    .col-lg-push-4\.5 {
        left: 37.5%;
    }
    .col-lg-push-3\.5 {
        left: 29.16666667%;
    }
    .col-lg-push-2\.5 {
        left: 20.83333333%;
    }
    .col-lg-push-1\.5 {
        left: 12.5%;
    }
    .col-lg-push-0\.5 {
        left: 4.16666667%;
    }

    .col-lg-offset-11\.5 {
        margin-left: 95.83333333%;
    }
    .col-lg-offset-10\.5 {
        margin-left: 87.5%;
    }
    .col-lg-offset-9\.5 {
        margin-left: 79.16666667%;
    }
    .col-lg-offset-8\.5 {
        margin-left: 70.83333333%;
    }
    .col-lg-offset-7\.5 {
        margin-left: 62.5%;
    }
    .col-lg-offset-6\.5 {
        margin-left: 54.16666667%;
    }
    .col-lg-offset-5\.5 {
        margin-left: 45.83333333%;
    }
    .col-lg-offset-4\.5 {
        margin-left: 37.5%;
    }
    .col-lg-offset-3\.5 {
        margin-left: 29.16666667%;
    }
    .col-lg-offset-2\.5 {
        margin-left: 20.83333333%;
    }
    .col-lg-offset-1\.5 {
        margin-left: 12.5%;
    }
    .col-lg-offset-0\.5 {
        margin-left: 4.16666667%;
    }
}
