/*! Source: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css */
@media print {
  *, *:before, *:after {
    background: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important
  }

  a, a:visited {
    text-decoration: underline
  }

  a[href]:after {
    content: " (" attr(href) ")"
  }

  abbr[title]:after {
    content: " (" attr(title) ")"
  }

  a[href^="#"]:after, a[href^="javascript:"]:after {
    content: ""
  }

  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid
  }

  thead {
    display: table-header-group
  }

  tr, img {
    page-break-inside: avoid
  }

  img {
    max-width: 100% !important
  }

  p, h2, h3 {
    orphans: 3;
    widows: 3
  }

  h2, h3 {
    page-break-after: avoid
  }

  .navbar {
    display: none
  }

  .btn > .caret, .dropup > .btn > .caret {
    border-top-color: #000 !important
  }

  .label {
    border: 1px solid #000
  }

  .table {
    border-collapse: collapse !important
  }

  .table td, .table th {
    background-color: #fff !important
  }

  .table-bordered th, .table-bordered td {
    border: 1px solid #ddd !important
  }
}

* {
  box-sizing: border-box
}

*:before, *:after {
  box-sizing: border-box
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased
}

body {
  font-family: "Frutiger Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5em; /*18px;*/
  line-height: 1.5;
  color: #333;
  background-color: #fff
}

.splash-screen-container {
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
}

.splash-screen {
  margin: auto;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

a {
  color: #069;
  text-decoration: none
}

a:hover, a:focus {
  color: #005580;
  text-decoration: none
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

figure {
  margin: 0
}

img {
  vertical-align: middle
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto
}

.img-rounded {
  border-radius: 0
}

.img-thumbnail {
  padding: 4px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  max-width: 100%;
  height: auto
}

.img-circle {
  border-radius: 50%
}

hr {
  margin-top: 27px;
  margin-bottom: 27px;
  border: 0;
  border-top: 1px solid #eee
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto
}

[role="button"] {
  cursor: pointer
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: inherit;
  font-weight: 600;
  line-height: 1.1;
  color: inherit
}

h1 small, h1 .small, h2 small, h2 .small, h3 small, h3 .small, h4 small, h4 .small, h5 small, h5 .small, h6 small, h6 .small, .h1 small, .h1 .small, .h2 small, .h2 .small, .h3 small, .h3 .small, .h4 small, .h4 .small, .h5 small, .h5 .small, .h6 small, .h6 .small {
  font-weight: normal;
  line-height: 1;
  color: #666
}

h1, .h1, h2, .h2, h3, .h3 {
  margin-top: 27px;
  margin-bottom: 13.5px
}

h1 small, h1 .small, .h1 small, .h1 .small, h2 small, h2 .small, .h2 small, .h2 .small, h3 small, h3 .small, .h3 small, .h3 .small {
  font-size: 65%
}

h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 13.5px;
  margin-bottom: 13.5px
}

h4 small, h4 .small, .h4 small, .h4 .small, h5 small, h5 .small, .h5 small, .h5 .small, h6 small, h6 .small, .h6 small, .h6 .small {
  font-size: 75%
}

h1, .h1 {
  font-size: 40px
}

h2, .h2 {
  font-size: 30px
}

h3, .h3 {
  font-size: 20px
}

h4, .h4 {
  font-size: 18px
}

h5, .h5 {
  font-size: 16px
}

h6, .h6 {
  font-size: 14px
}

p {
  margin: 0 0 13.5px
}

.lead {
  margin-bottom: 27px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4
}

@media (min-width: 768px) {
  .lead {
    font-size: 27px
  }
}

small, .small {
  font-size: 77%;
  color: #666
}

mark, .mark {
  background-color: #fcf8e3;
  padding: .2em
}

.text-wrap {
  white-space: pre-wrap;
}

.hide-overflow-x {
  overflow-x: hidden;
}

.scroll-overflow-y {
  overflow-y: scroll;
}

.max-vh-75 {
  max-height: 75vh;
}

.text-left {
  text-align: left
}

.text-right {
  text-align: right
}

.text-center {
  text-align: center
}

.text-justify {
  text-align: justify
}

.text-nowrap {
  white-space: nowrap
}

.text-lowercase {
  text-transform: lowercase
}

.text-uppercase, .initialism {
  text-transform: uppercase
}

.text-capitalize {
  text-transform: capitalize
}

.text-muted {
  color: #999
}

.text-primary {
  color: #069
}

a.text-primary:hover, a.text-primary:focus {
  color: #005580
}

.text-success {
  color: #3c763d
}

a.text-success:hover, a.text-success:focus {
  color: #2b542c
}

.text-info {
  color: #31708f
}

a.text-info:hover, a.text-info:focus {
  color: #245269
}

.text-warning {
  color: #8a6d3b
}

a.text-warning:hover, a.text-warning:focus {
  color: #66512c
}

.text-danger {
  color: #a94442
}

a.text-danger:hover, a.text-danger:focus {
  color: #843534
}

.bg-primary {
  color: #fff;
  background-color: #069
}

a.bg-primary:hover, a.bg-primary:focus {
  background-color: #005580
}

.bg-success {
  background-color: #dff0d8
}

a.bg-success:hover, a.bg-success:focus {
  background-color: #c1e2b3
}

.bg-info {
  background-color: #d9edf7
}

a.bg-info:hover, a.bg-info:focus {
  background-color: #afd9ee
}

.bg-warning {
  background-color: #fcf8e3
}

a.bg-warning:hover, a.bg-warning:focus {
  background-color: #f7ecb5
}

.bg-danger {
  background-color: #f2dede
}

a.bg-danger:hover, a.bg-danger:focus {
  background-color: #e4b9b9
}

.page-header {
  background-color: #f5f5f5;
  /*margin: 0 0 50px;*/
  padding: 12.5px 0 12.5px;
  border: 0;
  word-wrap: break-word
}

ul, ol {
  margin-top: 0;
  margin-bottom: 13.5px
}

ul ul, ul ol, ol ul, ol ol {
  margin-bottom: 0
}

.list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px
}

.list-inline > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px
}

dl {
  margin-top: 0;
  margin-bottom: 27px
}

dt, dd {
  line-height: 1.5
}

dt {
  font-weight: bold
}

dd {
  margin-left: 0
}

.dl-horizontal dd:before, .dl-horizontal dd:after {
  content: " ";
  display: table
}

.dl-horizontal dd:after {
  clear: both
}

@media (min-width: 992px) {
  .dl-horizontal dt {
    float: left;
    width: 180px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    /*white-space: nowrap*/
  }

  .dl-horizontal dd {
    margin-left: 200px
  }
}

abbr[title], abbr[data-original-title] {
  cursor: help;
  border-bottom: 1px dotted #999
}

.initialism {
  font-size: 90%
}

blockquote {
  padding: 13.5px 27px;
  margin: 0 0 27px;
  font-size: 18px;
  border-left: 5px solid #eee
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
  margin-bottom: 0
}

blockquote footer, blockquote small, blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.5;
  color: #999
}

blockquote footer:before, blockquote small:before, blockquote .small:before {
  content: '\2014 \00A0'
}

.blockquote-reverse, blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #eee;
  border-left: 0;
  text-align: right
}

.blockquote-reverse footer:before, .blockquote-reverse small:before, .blockquote-reverse .small:before, blockquote.pull-right footer:before, blockquote.pull-right small:before, blockquote.pull-right .small:before {
  content: ''
}

.blockquote-reverse footer:after, .blockquote-reverse small:after, .blockquote-reverse .small:after, blockquote.pull-right footer:after, blockquote.pull-right small:after, blockquote.pull-right .small:after {
  content: '\00A0 \2014'
}

address {
  margin-bottom: 27px;
  font-style: normal;
  line-height: 1.5
}

code, kbd, pre, samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 0
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 0;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25)
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: bold;
  box-shadow: none
}

pre {
  display: block;
  padding: 13px;
  margin: 0 0 13.5px;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
  color: #333;
  background-color: #f5f5f5;
  border: none;
  border-radius: 0
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px
}

.container:before, .container:after {
  content: " ";
  display: table
}

.container:after {
  clear: both
}

@media (min-width: 768px) {
  .container {
    width: 750px
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px
}

.container-fluid:before, .container-fluid:after {
  content: " ";
  display: table
}

.container-fluid:after {
  clear: both
}

.row {
  margin-left: -15px;
  margin-right: -15px
}

.row:before, .row:after {
  content: " ";
  display: table
}

.row:after {
  clear: both
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left
}

.col-xs-1 {
  width: 8.33333%
}

.col-xs-2 {
  width: 16.66667%
}

.col-xs-3 {
  width: 25%
}

.col-xs-4 {
  width: 33.33333%
}

.col-xs-5 {
  width: 41.66667%
}

.col-xs-6 {
  width: 50%
}

.col-xs-7 {
  width: 58.33333%
}

.col-xs-8 {
  width: 66.66667%
}

.col-xs-9 {
  width: 75%
}

.col-xs-10 {
  width: 83.33333%
}

.col-xs-11 {
  width: 91.66667%
}

.col-xs-12 {
  width: 100%
}

.col-xs-pull-0 {
  right: auto
}

.col-xs-pull-1 {
  right: 8.33333%
}

.col-xs-pull-2 {
  right: 16.66667%
}

.col-xs-pull-3 {
  right: 25%
}

.col-xs-pull-4 {
  right: 33.33333%
}

.col-xs-pull-5 {
  right: 41.66667%
}

.col-xs-pull-6 {
  right: 50%
}

.col-xs-pull-7 {
  right: 58.33333%
}

.col-xs-pull-8 {
  right: 66.66667%
}

.col-xs-pull-9 {
  right: 75%
}

.col-xs-pull-10 {
  right: 83.33333%
}

.col-xs-pull-11 {
  right: 91.66667%
}

.col-xs-pull-12 {
  right: 100%
}

.col-xs-push-0 {
  left: auto
}

.col-xs-push-1 {
  left: 8.33333%
}

.col-xs-push-2 {
  left: 16.66667%
}

.col-xs-push-3 {
  left: 25%
}

.col-xs-push-4 {
  left: 33.33333%
}

.col-xs-push-5 {
  left: 41.66667%
}

.col-xs-push-6 {
  left: 50%
}

.col-xs-push-7 {
  left: 58.33333%
}

.col-xs-push-8 {
  left: 66.66667%
}

.col-xs-push-9 {
  left: 75%
}

.col-xs-push-10 {
  left: 83.33333%
}

.col-xs-push-11 {
  left: 91.66667%
}

.col-xs-push-12 {
  left: 100%
}

.col-xs-offset-0 {
  margin-left: 0
}

.col-xs-offset-0-2 {
  margin-left: 1.66666%
}

.col-xs-offset-1 {
  margin-left: 8.33333%
}

.col-xs-offset-2 {
  margin-left: 16.66667%
}

.col-xs-offset-3 {
  margin-left: 25%
}

.col-xs-offset-4 {
  margin-left: 33.33333%
}

.col-xs-offset-5 {
  margin-left: 41.66667%
}

.col-xs-offset-6 {
  margin-left: 50%
}

.col-xs-offset-7 {
  margin-left: 58.33333%
}

.col-xs-offset-8 {
  margin-left: 66.66667%
}

.col-xs-offset-9 {
  margin-left: 75%
}

.col-xs-offset-10 {
  margin-left: 83.33333%
}

.col-xs-offset-11 {
  margin-left: 91.66667%
}

.col-xs-offset-12 {
  margin-left: 100%
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left
  }

  .col-sm-1 {
    width: 8.33333%
  }

  .col-sm-2 {
    width: 16.66667%
  }

  .col-sm-3 {
    width: 25%
  }

  .col-sm-4 {
    width: 33.33333%
  }

  .col-sm-5 {
    width: 41.66667%
  }

  .col-sm-6 {
    width: 50%
  }

  .col-sm-7 {
    width: 58.33333%
  }

  .col-sm-8 {
    width: 66.66667%
  }

  .col-sm-9 {
    width: 75%
  }

  .col-sm-10 {
    width: 83.33333%
  }

  .col-sm-11 {
    width: 91.66667%
  }

  .col-sm-12 {
    width: 100%
  }

  .col-sm-pull-0 {
    right: auto
  }

  .col-sm-pull-1 {
    right: 8.33333%
  }

  .col-sm-pull-2 {
    right: 16.66667%
  }

  .col-sm-pull-3 {
    right: 25%
  }

  .col-sm-pull-4 {
    right: 33.33333%
  }

  .col-sm-pull-5 {
    right: 41.66667%
  }

  .col-sm-pull-6 {
    right: 50%
  }

  .col-sm-pull-7 {
    right: 58.33333%
  }

  .col-sm-pull-8 {
    right: 66.66667%
  }

  .col-sm-pull-9 {
    right: 75%
  }

  .col-sm-pull-10 {
    right: 83.33333%
  }

  .col-sm-pull-11 {
    right: 91.66667%
  }

  .col-sm-pull-12 {
    right: 100%
  }

  .col-sm-push-0 {
    left: auto
  }

  .col-sm-push-1 {
    left: 8.33333%
  }

  .col-sm-push-2 {
    left: 16.66667%
  }

  .col-sm-push-3 {
    left: 25%
  }

  .col-sm-push-4 {
    left: 33.33333%
  }

  .col-sm-push-5 {
    left: 41.66667%
  }

  .col-sm-push-6 {
    left: 50%
  }

  .col-sm-push-7 {
    left: 58.33333%
  }

  .col-sm-push-8 {
    left: 66.66667%
  }

  .col-sm-push-9 {
    left: 75%
  }

  .col-sm-push-10 {
    left: 83.33333%
  }

  .col-sm-push-11 {
    left: 91.66667%
  }

  .col-sm-push-12 {
    left: 100%
  }

  .col-sm-offset-0 {
    margin-left: 0
  }

  .col-sm-offset-1 {
    margin-left: 8.33333%
  }

  .col-sm-offset-2 {
    margin-left: 16.66667%
  }

  .col-sm-offset-3 {
    margin-left: 25%
  }

  .col-sm-offset-4 {
    margin-left: 33.33333%
  }

  .col-sm-offset-5 {
    margin-left: 41.66667%
  }

  .col-sm-offset-6 {
    margin-left: 50%
  }

  .col-sm-offset-7 {
    margin-left: 58.33333%
  }

  .col-sm-offset-8 {
    margin-left: 66.66667%
  }

  .col-sm-offset-9 {
    margin-left: 75%
  }

  .col-sm-offset-10 {
    margin-left: 83.33333%
  }

  .col-sm-offset-11 {
    margin-left: 91.66667%
  }

  .col-sm-offset-12 {
    margin-left: 100%
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left
  }

  .col-md-1 {
    width: 8.33333%
  }

  .col-md-2 {
    width: 16.66667%
  }

  .col-md-3 {
    width: 25%
  }

  .col-md-4 {
    width: 33.33333%
  }

  .col-md-5 {
    width: 41.66667%
  }

  .col-md-6 {
    width: 50%
  }

  .col-md-7 {
    width: 58.33333%
  }

  .col-md-8 {
    width: 66.66667%
  }

  .col-md-9 {
    width: 75%
  }

  .col-md-10 {
    width: 83.33333%
  }

  .col-md-11 {
    width: 91.66667%
  }

  .col-md-12 {
    width: 100%
  }

  .col-md-pull-0 {
    right: auto
  }

  .col-md-pull-1 {
    right: 8.33333%
  }

  .col-md-pull-2 {
    right: 16.66667%
  }

  .col-md-pull-3 {
    right: 25%
  }

  .col-md-pull-4 {
    right: 33.33333%
  }

  .col-md-pull-5 {
    right: 41.66667%
  }

  .col-md-pull-6 {
    right: 50%
  }

  .col-md-pull-7 {
    right: 58.33333%
  }

  .col-md-pull-8 {
    right: 66.66667%
  }

  .col-md-pull-9 {
    right: 75%
  }

  .col-md-pull-10 {
    right: 83.33333%
  }

  .col-md-pull-11 {
    right: 91.66667%
  }

  .col-md-pull-12 {
    right: 100%
  }

  .col-md-push-0 {
    left: auto
  }

  .col-md-push-1 {
    left: 8.33333%
  }

  .col-md-push-2 {
    left: 16.66667%
  }

  .col-md-push-3 {
    left: 25%
  }

  .col-md-push-4 {
    left: 33.33333%
  }

  .col-md-push-5 {
    left: 41.66667%
  }

  .col-md-push-6 {
    left: 50%
  }

  .col-md-push-7 {
    left: 58.33333%
  }

  .col-md-push-8 {
    left: 66.66667%
  }

  .col-md-push-9 {
    left: 75%
  }

  .col-md-push-10 {
    left: 83.33333%
  }

  .col-md-push-11 {
    left: 91.66667%
  }

  .col-md-push-12 {
    left: 100%
  }

  .col-md-offset-0 {
    margin-left: 0
  }

  .col-md-offset-0-2 {
    margin-left: 1.66666%
  }

  .col-md-offset-1 {
    margin-left: 8.33333%
  }

  .col-md-offset-2 {
    margin-left: 16.66667%
  }

  .col-md-offset-3 {
    margin-left: 25%
  }

  .col-md-offset-4 {
    margin-left: 33.33333%
  }

  .col-md-offset-5 {
    margin-left: 41.66667%
  }

  .col-md-offset-6 {
    margin-left: 50%
  }

  .col-md-offset-7 {
    margin-left: 58.33333%
  }

  .col-md-offset-8 {
    margin-left: 66.66667%
  }

  .col-md-offset-9 {
    margin-left: 75%
  }

  .col-md-offset-10 {
    margin-left: 83.33333%
  }

  .col-md-offset-11 {
    margin-left: 91.66667%
  }

  .col-md-offset-12 {
    margin-left: 100%
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left
  }

  .col-lg-1 {
    width: 8.33333%
  }

  .col-lg-2 {
    width: 16.66667%
  }

  .col-lg-3 {
    width: 25%
  }

  .col-lg-4 {
    width: 33.33333%
  }

  .col-lg-5 {
    width: 41.66667%
  }

  .col-lg-6 {
    width: 50%
  }

  .col-lg-7 {
    width: 58.33333%
  }

  .col-lg-8 {
    width: 66.66667%
  }

  .col-lg-9 {
    width: 75%
  }

  .col-lg-10 {
    width: 83.33333%
  }

  .col-lg-11 {
    width: 91.66667%
  }

  .col-lg-12 {
    width: 100%
  }

  .col-lg-pull-0 {
    right: auto
  }

  .col-lg-pull-1 {
    right: 8.33333%
  }

  .col-lg-pull-2 {
    right: 16.66667%
  }

  .col-lg-pull-3 {
    right: 25%
  }

  .col-lg-pull-4 {
    right: 33.33333%
  }

  .col-lg-pull-5 {
    right: 41.66667%
  }

  .col-lg-pull-6 {
    right: 50%
  }

  .col-lg-pull-7 {
    right: 58.33333%
  }

  .col-lg-pull-8 {
    right: 66.66667%
  }

  .col-lg-pull-9 {
    right: 75%
  }

  .col-lg-pull-10 {
    right: 83.33333%
  }

  .col-lg-pull-11 {
    right: 91.66667%
  }

  .col-lg-pull-12 {
    right: 100%
  }

  .col-lg-push-0 {
    left: auto
  }

  .col-lg-push-1 {
    left: 8.33333%
  }

  .col-lg-push-2 {
    left: 16.66667%
  }

  .col-lg-push-3 {
    left: 25%
  }

  .col-lg-push-4 {
    left: 33.33333%
  }

  .col-lg-push-5 {
    left: 41.66667%
  }

  .col-lg-push-6 {
    left: 50%
  }

  .col-lg-push-7 {
    left: 58.33333%
  }

  .col-lg-push-8 {
    left: 66.66667%
  }

  .col-lg-push-9 {
    left: 75%
  }

  .col-lg-push-10 {
    left: 83.33333%
  }

  .col-lg-push-11 {
    left: 91.66667%
  }

  .col-lg-push-12 {
    left: 100%
  }

  .col-lg-offset-0 {
    margin-left: 0
  }

  .col-lg-offset-1 {
    margin-left: 8.33333%
  }

  .col-lg-offset-2 {
    margin-left: 16.66667%
  }

  .col-lg-offset-3 {
    margin-left: 25%
  }

  .col-lg-offset-4 {
    margin-left: 33.33333%
  }

  .col-lg-offset-5 {
    margin-left: 41.66667%
  }

  .col-lg-offset-6 {
    margin-left: 50%
  }

  .col-lg-offset-7 {
    margin-left: 58.33333%
  }

  .col-lg-offset-8 {
    margin-left: 66.66667%
  }

  .col-lg-offset-9 {
    margin-left: 75%
  }

  .col-lg-offset-10 {
    margin-left: 83.33333%
  }

  .col-lg-offset-11 {
    margin-left: 91.66667%
  }

  .col-lg-offset-12 {
    margin-left: 100%
  }
}

table {
  background-color: transparent
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #999;
  text-align: left
}

th {
  text-align: left
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 27px
}

.table > thead > tr > th, .table > thead > tr > td, .table > tbody > tr > th, .table > tbody > tr > td, .table > tfoot > tr > th, .table > tfoot > tr > td {
  padding: 8px;
  line-height: 1.5;
  vertical-align: top;
  border-top: 1px solid #ddd
}

.table > thead > tr > th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd
}

.table > caption + thead > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > th, .table > thead:first-child > tr:first-child > td {
  border-top: 0
}

.table > tbody + tbody {
  border-top: 2px solid #ddd
}

.table .table {
  background-color: #fff
}

.table-condensed > thead > tr > th, .table-condensed > thead > tr > td, .table-condensed > tbody > tr > th, .table-condensed > tbody > tr > td, .table-condensed > tfoot > tr > th, .table-condensed > tfoot > tr > td {
  padding: 5px
}

.table-bordered {
  border: 1px solid #ddd
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td, .table-bordered > tbody > tr > th, .table-bordered > tbody > tr > td, .table-bordered > tfoot > tr > th, .table-bordered > tfoot > tr > td {
  border: 1px solid #ddd
}

.table-bordered > thead > tr > th, .table-bordered > thead > tr > td {
  border-bottom-width: 2px
}

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9
}

.table-hover > tbody > tr:hover {
  background-color: #f5f5f5
}

table col[class*="col-"] {
  position: static;
  float: none;
  display: table-column
}

table td[class*="col-"], table th[class*="col-"] {
  position: static;
  float: none;
  display: table-cell
}

.table > thead > tr > td.active, .table > thead > tr > th.active, .table > thead > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr > td.active, .table > tbody > tr > th.active, .table > tbody > tr.active > td, .table > tbody > tr.active > th, .table > tfoot > tr > td.active, .table > tfoot > tr > th.active, .table > tfoot > tr.active > td, .table > tfoot > tr.active > th {
  background-color: #f5f5f5
}

.table-hover > tbody > tr > td.active:hover, .table-hover > tbody > tr > th.active:hover, .table-hover > tbody > tr.active:hover > td, .table-hover > tbody > tr:hover > .active, .table-hover > tbody > tr.active:hover > th {
  background-color: #e8e8e8
}

.table > thead > tr > td.success, .table > thead > tr > th.success, .table > thead > tr.success > td, .table > thead > tr.success > th, .table > tbody > tr > td.success, .table > tbody > tr > th.success, .table > tbody > tr.success > td, .table > tbody > tr.success > th, .table > tfoot > tr > td.success, .table > tfoot > tr > th.success, .table > tfoot > tr.success > td, .table > tfoot > tr.success > th {
  background-color: #dff0d8
}

.table-hover > tbody > tr > td.success:hover, .table-hover > tbody > tr > th.success:hover, .table-hover > tbody > tr.success:hover > td, .table-hover > tbody > tr:hover > .success, .table-hover > tbody > tr.success:hover > th {
  background-color: #d0e9c6
}

.table > thead > tr > td.info, .table > thead > tr > th.info, .table > thead > tr.info > td, .table > thead > tr.info > th, .table > tbody > tr > td.info, .table > tbody > tr > th.info, .table > tbody > tr.info > td, .table > tbody > tr.info > th, .table > tfoot > tr > td.info, .table > tfoot > tr > th.info, .table > tfoot > tr.info > td, .table > tfoot > tr.info > th {
  background-color: #d9edf7
}

.table-hover > tbody > tr > td.info:hover, .table-hover > tbody > tr > th.info:hover, .table-hover > tbody > tr.info:hover > td, .table-hover > tbody > tr:hover > .info, .table-hover > tbody > tr.info:hover > th {
  background-color: #c4e3f3
}

.table > thead > tr > td.warning, .table > thead > tr > th.warning, .table > thead > tr.warning > td, .table > thead > tr.warning > th, .table > tbody > tr > td.warning, .table > tbody > tr > th.warning, .table > tbody > tr.warning > td, .table > tbody > tr.warning > th, .table > tfoot > tr > td.warning, .table > tfoot > tr > th.warning, .table > tfoot > tr.warning > td, .table > tfoot > tr.warning > th {
  background-color: #fcf8e3
}

.table-hover > tbody > tr > td.warning:hover, .table-hover > tbody > tr > th.warning:hover, .table-hover > tbody > tr.warning:hover > td, .table-hover > tbody > tr:hover > .warning, .table-hover > tbody > tr.warning:hover > th {
  background-color: #faf2cc
}

.table > thead > tr > td.danger, .table > thead > tr > th.danger, .table > thead > tr.danger > td, .table > thead > tr.danger > th, .table > tbody > tr > td.danger, .table > tbody > tr > th.danger, .table > tbody > tr.danger > td, .table > tbody > tr.danger > th, .table > tfoot > tr > td.danger, .table > tfoot > tr > th.danger, .table > tfoot > tr.danger > td, .table > tfoot > tr.danger > th {
  background-color: #f2dede
}

.table-hover > tbody > tr > td.danger:hover, .table-hover > tbody > tr > th.danger:hover, .table-hover > tbody > tr.danger:hover > td, .table-hover > tbody > tr:hover > .danger, .table-hover > tbody > tr.danger:hover > th {
  background-color: #ebcccc
}

.table-responsive {
  overflow-x: auto;
  min-height: 0.01%
}

@media screen and (max-width: 767px) {
  .table-responsive {
    width: 100%;
    margin-bottom: 20.25px;
    overflow-y: hidden;
    border: 1px solid #ddd
  }

  .table-responsive > .table {
    margin-bottom: 0
  }

  .table-responsive > .table > thead > tr > th, .table-responsive > .table > thead > tr > td, .table-responsive > .table > tbody > tr > th, .table-responsive > .table > tbody > tr > td, .table-responsive > .table > tfoot > tr > th, .table-responsive > .table > tfoot > tr > td {
    white-space: nowrap
  }

  .table-responsive > .table-bordered {
    border: 0
  }

  .table-responsive > .table-bordered > thead > tr > th:first-child, .table-responsive > .table-bordered > thead > tr > td:first-child, .table-responsive > .table-bordered > tbody > tr > th:first-child, .table-responsive > .table-bordered > tbody > tr > td:first-child, .table-responsive > .table-bordered > tfoot > tr > th:first-child, .table-responsive > .table-bordered > tfoot > tr > td:first-child {
    border-left: 0
  }

  .table-responsive > .table-bordered > thead > tr > th:last-child, .table-responsive > .table-bordered > thead > tr > td:last-child, .table-responsive > .table-bordered > tbody > tr > th:last-child, .table-responsive > .table-bordered > tbody > tr > td:last-child, .table-responsive > .table-bordered > tfoot > tr > th:last-child, .table-responsive > .table-bordered > tfoot > tr > td:last-child {
    border-right: 0
  }

  .table-responsive > .table-bordered > tbody > tr:last-child > th, .table-responsive > .table-bordered > tbody > tr:last-child > td, .table-responsive > .table-bordered > tfoot > tr:last-child > th, .table-responsive > .table-bordered > tfoot > tr:last-child > td {
    border-bottom: 0
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 27px;
  font-size: 27px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold
}

input[type="search"] {
  box-sizing: border-box;
  -webkit-appearance: none
}

input[type="radio"], input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal
}

input[type="file"] {
  display: block
}

input[type="range"] {
  display: block;
  width: 100%
}

select[multiple], select[size] {
  height: auto
}

input[type="file"]:focus, input[type="radio"]:focus, input[type="checkbox"]:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

output {
  display: block;
  padding-top: 9px;
  font-size: 18px;
  line-height: 1.5;
  color: #333
}

.form-control {
  display: block;
  width: 100%;
  height: 45px;
  padding: 8px 12px;
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #333;
  border-radius: 0;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s
}

.form-control:focus {
  border-color: #069;
  outline: 0;
  box-shadow: none
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1
}

.form-control::-webkit-input-placeholder {
  color: #999
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1
}

.form-control[disabled], fieldset[disabled] .form-control {
  cursor: not-allowed
}

textarea.form-control {
  height: auto
}

div.input-reset-button>button {
  position: relative;
  border: none;
  display: block;
  width: 15px;
  height: 15px;
  line-height: 14px;
  font-size: 12px;
  border-radius: 50%;
  top: -24px;
  bottom: 0;
  right: 5px;
  float: right;
  background: #979797;
  padding: 0;
  outline: none;
  cursor: pointer;
  transition: .1s;
  color: white;
}

div.input-reset-button>button>i {
  margin-left: 1px;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control, input[type="time"].form-control, input[type="datetime-local"].form-control, input[type="month"].form-control {
    line-height: 45px
  }

  input[type="date"].input-sm, .input-group-sm > input[type="date"].form-control, .input-group-sm > input[type="date"].input-group-addon, .input-group-sm > .input-group-btn > input[type="date"].btn, .input-group-sm input[type="date"], input[type="time"].input-sm, .input-group-sm > input[type="time"].form-control, .input-group-sm > input[type="time"].input-group-addon, .input-group-sm > .input-group-btn > input[type="time"].btn, .input-group-sm input[type="time"], input[type="datetime-local"].input-sm, .input-group-sm > input[type="datetime-local"].form-control, .input-group-sm > input[type="datetime-local"].input-group-addon, .input-group-sm > .input-group-btn > input[type="datetime-local"].btn, .input-group-sm input[type="datetime-local"], input[type="month"].input-sm, .input-group-sm > input[type="month"].form-control, .input-group-sm > input[type="month"].input-group-addon, .input-group-sm > .input-group-btn > input[type="month"].btn, .input-group-sm input[type="month"] {
    line-height: 33px
  }

  input[type="date"].input-lg, .input-group-lg > input[type="date"].form-control, .input-group-lg > input[type="date"].input-group-addon, .input-group-lg > .input-group-btn > input[type="date"].btn, .input-group-lg input[type="date"], input[type="time"].input-lg, .input-group-lg > input[type="time"].form-control, .input-group-lg > input[type="time"].input-group-addon, .input-group-lg > .input-group-btn > input[type="time"].btn, .input-group-lg input[type="time"], input[type="datetime-local"].input-lg, .input-group-lg > input[type="datetime-local"].form-control, .input-group-lg > input[type="datetime-local"].input-group-addon, .input-group-lg > .input-group-btn > input[type="datetime-local"].btn, .input-group-lg input[type="datetime-local"], input[type="month"].input-lg, .input-group-lg > input[type="month"].form-control, .input-group-lg > input[type="month"].input-group-addon, .input-group-lg > .input-group-btn > input[type="month"].btn, .input-group-lg input[type="month"] {
    line-height: 63px
  }
}

.form-group {
  margin-bottom: 15px
}

.radio, .checkbox {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px
}

.radio label, .checkbox label {
  min-height: 27px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer
}

.radio input[type="radio"], .radio-inline input[type="radio"], .checkbox input[type="checkbox"], .checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9
}

.radio + .radio, .checkbox + .checkbox {
  margin-top: -5px
}

.radio-inline, .checkbox-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer
}

.radio-inline + .radio-inline, .checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 10px
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"], input[type="checkbox"][disabled], input[type="checkbox"].disabled, fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed
}

.radio-inline.disabled, fieldset[disabled] .radio-inline, .checkbox-inline.disabled, fieldset[disabled] .checkbox-inline {
  cursor: not-allowed
}

.radio.disabled label, fieldset[disabled] .radio label, .checkbox.disabled label, fieldset[disabled] .checkbox label {
  cursor: not-allowed
}

.form-control-static {
  padding-top: 9px;
  padding-bottom: 9px;
  margin-bottom: 0;
  min-height: 45px
}

.form-control-static.input-lg, .input-group-lg > .form-control-static.form-control, .input-group-lg > .form-control-static.input-group-addon, .input-group-lg > .input-group-btn > .form-control-static.btn, .form-control-static.input-sm, .input-group-sm > .form-control-static.form-control, .input-group-sm > .form-control-static.input-group-addon, .input-group-sm > .input-group-btn > .form-control-static.btn {
  padding-left: 0;
  padding-right: 0
}

.input-sm, .input-group-sm > .form-control, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .btn {
  height: 33px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0
}

select.input-sm, .input-group-sm > select.form-control, .input-group-sm > select.input-group-addon, .input-group-sm > .input-group-btn > select.btn {
  height: 33px;
  line-height: 33px
}

textarea.input-sm, .input-group-sm > textarea.form-control, .input-group-sm > textarea.input-group-addon, .input-group-sm > .input-group-btn > textarea.btn, select[multiple].input-sm, .input-group-sm > select[multiple].form-control, .input-group-sm > select[multiple].input-group-addon, .input-group-sm > .input-group-btn > select[multiple].btn {
  height: auto
}

.form-group-sm .form-control {
  height: 33px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 0
}

.form-group-sm select.form-control {
  height: 33px;
  line-height: 33px
}

.form-group-sm textarea.form-control, .form-group-sm select[multiple].form-control {
  height: auto
}

.form-group-sm .form-control-static {
  height: 33px;
  min-height: 41px;
  padding: 6px 10px;
  font-size: 14px;
  line-height: 1.5
}

.input-lg, .input-group-lg > .form-control, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .btn {
  height: 63px;
  padding: 10px 16px;
  font-size: 23px;
  line-height: 1.33333;
  border-radius: 0
}

select.input-lg, .input-group-lg > select.form-control, .input-group-lg > select.input-group-addon, .input-group-lg > .input-group-btn > select.btn {
  height: 63px;
  line-height: 63px
}

textarea.input-lg, .input-group-lg > textarea.form-control, .input-group-lg > textarea.input-group-addon, .input-group-lg > .input-group-btn > textarea.btn, select[multiple].input-lg, .input-group-lg > select[multiple].form-control, .input-group-lg > select[multiple].input-group-addon, .input-group-lg > .input-group-btn > select[multiple].btn {
  height: auto
}

.form-group-lg .form-control {
  height: 63px;
  padding: 10px 16px;
  font-size: 23px;
  line-height: 1.33333;
  border-radius: 0
}

.form-group-lg select.form-control {
  height: 63px;
  line-height: 63px
}

.form-group-lg textarea.form-control, .form-group-lg select[multiple].form-control {
  height: auto
}

.form-group-lg .form-control-static {
  height: 63px;
  min-height: 50px;
  padding: 11px 16px;
  font-size: 23px;
  line-height: 1.33333
}

.has-feedback {
  position: relative
}

.has-feedback .form-control {
  padding-right: 56.25px
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  pointer-events: none;
  color: #333
}

.input-lg + .form-control-feedback, .input-group-lg > .form-control + .form-control-feedback, .input-group-lg > .input-group-addon + .form-control-feedback, .input-group-lg > .input-group-btn > .btn + .form-control-feedback, .input-group-lg + .form-control-feedback, .form-group-lg .form-control + .form-control-feedback {
  width: 63px;
  height: 63px;
  line-height: 63px
}

.input-sm + .form-control-feedback, .input-group-sm > .form-control + .form-control-feedback, .input-group-sm > .input-group-addon + .form-control-feedback, .input-group-sm > .input-group-btn > .btn + .form-control-feedback, .input-group-sm + .form-control-feedback, .form-group-sm .form-control + .form-control-feedback {
  width: 33px;
  height: 33px;
  line-height: 33px
}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox, .has-success .radio-inline, .has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
  color: #3c763d
}

.has-success .form-control {
  border-color: #3c763d;

  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-success .form-control:focus {
  border-color: #2b542c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168
}

.has-success .input-group-addon {
  color: #3c763d;
  border-color: #3c763d;
  background-color: #dff0d8
}

.has-success .form-control-feedback {
  color: #3c763d
}

.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox, .has-warning .radio-inline, .has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
  color: #8a6d3b
}

.has-warning .form-control {
  border-color: #8a6d3b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-warning .form-control:focus {
  border-color: #66512c;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  border-color: #8a6d3b;
  background-color: #fcf8e3
}

.has-warning .form-control-feedback {
  color: #8a6d3b
}

.has-error .help-block, .nf-form-cont .nf-error .help-block, .has-error .control-label, .nf-form-cont .nf-error .control-label, .has-error .radio, .nf-form-cont .nf-error .radio, .has-error .checkbox, .nf-form-cont .nf-error .checkbox, .has-error .radio-inline, .nf-form-cont .nf-error .radio-inline, .has-error .checkbox-inline, .nf-form-cont .nf-error .checkbox-inline, .has-error.radio label, .nf-form-cont .radio.nf-error label, .has-error.checkbox label, .nf-form-cont .checkbox.nf-error label, .has-error.radio-inline label, .nf-form-cont .radio-inline.nf-error label, .has-error.checkbox-inline label, .nf-form-cont .checkbox-inline.nf-error label {
  color: #a94442
}

.has-error .form-control, .nf-form-cont .nf-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075)
}

.has-error .form-control:focus, .nf-form-cont .nf-error .form-control:focus {
  border-color: #843534;
  box-shadow: none
}

.has-error .input-group-addon, .nf-form-cont .nf-error .input-group-addon {
  color: #a94442;
  border-color: #a94442;
  background-color: #f2dede
}

.has-error .form-control-feedback, .nf-form-cont .nf-error .form-control-feedback {
  color: #a94442
}

.has-feedback label ~ .form-control-feedback {
  top: 32px
}

.has-feedback label.sr-only ~ .form-control-feedback {
  top: 0
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .form-inline .form-control-static {
    display: inline-block
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle
  }

  .form-inline .input-group .input-group-addon, .form-inline .input-group .input-group-btn, .form-inline .input-group .form-control {
    width: auto
  }

  .form-inline .input-group > .form-control {
    width: 100%
  }

  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle
  }

  .form-inline .radio, .form-inline .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle
  }

  .form-inline .radio label, .form-inline .checkbox label {
    padding-left: 0
  }

  .form-inline .radio input[type="radio"], .form-inline .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0
  }
}

.form-horizontal .radio, .form-horizontal .checkbox, .form-horizontal .radio-inline, .form-horizontal .checkbox-inline {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 9px
}

.form-horizontal .radio, .form-horizontal .checkbox {
  min-height: 36px
}

.form-horizontal .form-group {
  margin-left: -15px;
  margin-right: -15px
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
  content: " ";
  display: table
}

.form-horizontal .form-group:after {
  clear: both
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 9px
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 23px
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 14px
  }
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: normal;
  padding: 8px 12px;
  font-size: 18px;
  line-height: 1.5;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}

.btn:focus, .btn.focus, .btn:active:focus, .btn:active.focus, .btn.active:focus, .btn.active.focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px
}

.btn:hover, .btn:focus, .btn.focus {
  color: #069;
  text-decoration: none
}

.btn:active, .btn.active {
  outline: 0;
  background-image: none;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn.disabled, .btn[disabled], fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: .65;
  filter: alpha(opacity=65);
  box-shadow: none
}

a.btn.disabled, fieldset[disabled] a.btn {
  pointer-events: none
}

.btn-default {
  color: #069;
  background-color: #fff;
  border-color: #069
}

.btn-default:focus, .btn-default.focus {
  color: #069;
  background-color: #fff;
  border-color: #069
}

.btn-default:hover {
  color: #069;
  background-color: #fff;
  border-color: #069
}

.btn-default:active, .btn-default.active, .open > .btn-default.dropdown-toggle {
  color: #069;
  background-color: #fff;
  border-color: #069;
  background-image: none
}

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus, .open > .btn-default.dropdown-toggle:hover, .open > .btn-default.dropdown-toggle:focus, .open > .btn-default.dropdown-toggle.focus {
  color: #069;
  background-color: #fff;
  border-color: #069
}

.btn-default.disabled:hover, .btn-default.disabled:focus, .btn-default.disabled.focus, .btn-default[disabled]:hover, .btn-default[disabled]:focus, .btn-default[disabled].focus, fieldset[disabled] .btn-default:hover, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #069
}

.btn-default .badge {
  color: #fff;
  background-color: #069
}

.btn-primary {
  color: #fff;
  background-color: #069;
  border-color: #069
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #005580;
  border-color: #005580
}

.btn-primary:hover {
  color: #fff;
  background-color: #005580;
  border-color: #005580
}

.btn-primary:active, .btn-primary.active, .open > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #005580;
  border-color: #005580;
  background-image: none
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:hover, .btn-primary.active:focus, .btn-primary.active.focus, .open > .btn-primary.dropdown-toggle:hover, .open > .btn-primary.dropdown-toggle:focus, .open > .btn-primary.dropdown-toggle.focus {
  color: #fff;
  background-color: #005580;
  border-color: #005580
}

.btn-primary.disabled:hover, .btn-primary.disabled:focus, .btn-primary.disabled.focus, .btn-primary[disabled]:hover, .btn-primary[disabled]:focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary:hover, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary.focus {
  background-color: #069;
  border-color: #069
}

.btn-primary .badge {
  color: #069;
  background-color: #fff
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44
}

.btn-success:active, .btn-success.active, .open > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  border-color: #449d44;
  background-image: none
}

.btn-success:active:hover, .btn-success:active:focus, .btn-success:active.focus, .btn-success.active:hover, .btn-success.active:focus, .btn-success.active.focus, .open > .btn-success.dropdown-toggle:hover, .open > .btn-success.dropdown-toggle:focus, .open > .btn-success.dropdown-toggle.focus {
  color: #fff;
  background-color: #398439;
  border-color: #398439
}

.btn-success.disabled:hover, .btn-success.disabled:focus, .btn-success.disabled.focus, .btn-success[disabled]:hover, .btn-success[disabled]:focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success:hover, fieldset[disabled] .btn-success:focus, fieldset[disabled] .btn-success.focus {
  background-color: #5cb85c;
  border-color: #5cb85c
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5
}

.btn-info:active, .btn-info.active, .open > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  border-color: #31b0d5;
  background-image: none
}

.btn-info:active:hover, .btn-info:active:focus, .btn-info:active.focus, .btn-info.active:hover, .btn-info.active:focus, .btn-info.active.focus, .open > .btn-info.dropdown-toggle:hover, .open > .btn-info.dropdown-toggle:focus, .open > .btn-info.dropdown-toggle.focus {
  color: #fff;
  background-color: #269abc;
  border-color: #269abc
}

.btn-info.disabled:hover, .btn-info.disabled:focus, .btn-info.disabled.focus, .btn-info[disabled]:hover, .btn-info[disabled]:focus, .btn-info[disabled].focus, fieldset[disabled] .btn-info:hover, fieldset[disabled] .btn-info:focus, fieldset[disabled] .btn-info.focus {
  background-color: #5bc0de;
  border-color: #5bc0de
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-warning:focus, .btn-warning.focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f
}

.btn-warning:active, .btn-warning.active, .open > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  border-color: #ec971f;
  background-image: none
}

.btn-warning:active:hover, .btn-warning:active:focus, .btn-warning:active.focus, .btn-warning.active:hover, .btn-warning.active:focus, .btn-warning.active.focus, .open > .btn-warning.dropdown-toggle:hover, .open > .btn-warning.dropdown-toggle:focus, .open > .btn-warning.dropdown-toggle.focus {
  color: #fff;
  background-color: #d58512;
  border-color: #d58512
}

.btn-warning.disabled:hover, .btn-warning.disabled:focus, .btn-warning.disabled.focus, .btn-warning[disabled]:hover, .btn-warning[disabled]:focus, .btn-warning[disabled].focus, fieldset[disabled] .btn-warning:hover, fieldset[disabled] .btn-warning:focus, fieldset[disabled] .btn-warning.focus {
  background-color: #f0ad4e;
  border-color: #f0ad4e
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c
}

.btn-danger:active, .btn-danger.active, .open > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #c9302c;
  background-image: none
}

.btn-danger:active:hover, .btn-danger:active:focus, .btn-danger:active.focus, .btn-danger.active:hover, .btn-danger.active:focus, .btn-danger.active.focus, .open > .btn-danger.dropdown-toggle:hover, .open > .btn-danger.dropdown-toggle:focus, .open > .btn-danger.dropdown-toggle.focus {
  color: #fff;
  background-color: #ac2925;
  border-color: #ac2925
}

.btn-danger.disabled:hover, .btn-danger.disabled:focus, .btn-danger.disabled.focus, .btn-danger[disabled]:hover, .btn-danger[disabled]:focus, .btn-danger[disabled].focus, fieldset[disabled] .btn-danger:hover, fieldset[disabled] .btn-danger:focus, fieldset[disabled] .btn-danger.focus {
  background-color: #d9534f;
  border-color: #d9534f
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff
}

.btn-link {
  color: #069;
  font-weight: normal;
  border-radius: 0
}

.btn-link, .btn-link:active, .btn-link.active, .btn-link[disabled], fieldset[disabled] .btn-link {
  background-color: transparent;
  box-shadow: none
}

.btn-link, .btn-link:hover, .btn-link:focus, .btn-link:active {
  border-color: transparent
}

.btn-link:hover, .btn-link:focus {
  color: #005580;
  text-decoration: none;
  background-color: transparent
}

.btn-link[disabled]:hover, .btn-link[disabled]:focus, fieldset[disabled] .btn-link:hover, fieldset[disabled] .btn-link:focus {
  color: #999;
  text-decoration: none
}

.btn-lg, .btn-group-lg > .btn {
  padding: 10px 16px;
  font-size: 23px;
  line-height: 1.33333;
  border-radius: 6px
}

.btn-sm, .btn-group-sm > .btn {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px
}

.btn-xs, .btn-group-xs > .btn {
  padding: 1px 5px;
  font-size: 14px;
  line-height: 1.5;
  border-radius: 3px
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block + .btn-block {
  margin-top: 5px
}

input[type="submit"].btn-block, input[type="reset"].btn-block, input[type="button"].btn-block {
  width: 100%
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear
}

.fade.in {
  opacity: 1
}

.collapse {
  display: none
}

.collapse.in {
  display: block
}

tr.collapse.in {
  display: table-row
}

tbody.collapse.in {
  display: table-row-group
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.35s;
  transition-timing-function: ease
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent
}

.dropup, .dropdown {
  position: relative
}

.dropdown-toggle:focus {
  outline: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 18px;
  text-align: left;
  background-color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto
}

.dropdown-menu .divider {
  height: 1px;
  margin: 12.5px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.5;
  color: #333;
  white-space: nowrap
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  text-decoration: none;
  color: #fff;
  background-color: #069
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  background-color: #069
}

.dropdown-menu > .disabled > a, .dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  color: #999
}

.dropdown-menu > .disabled > a:hover, .dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  cursor: not-allowed
}

.open > .dropdown-menu {
  display: block
}

.open > a {
  outline: 0
}

.dropdown-menu-right {
  left: auto;
  right: 0
}

.dropdown-menu-left {
  left: 0;
  right: auto
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 14px;
  line-height: 1.5;
  color: #999;
  white-space: nowrap
}

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: ""
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px
}

@media (min-width: 992px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto
  }

  .navbar-right .dropdown-menu-left {
    left: 0;
    right: auto
  }
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle
}

.btn-group > .btn, .btn-group-vertical > .btn {
  position: relative;
  float: left
}

.btn-group > .btn:hover, .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active, .btn-group-vertical > .btn:hover, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn.active {
  z-index: 2
}

.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
  margin-left: -1px
}

.btn-toolbar {
  margin-left: -5px
}

.btn-toolbar:before, .btn-toolbar:after {
  content: " ";
  display: table
}

.btn-toolbar:after {
  clear: both
}

.btn-toolbar .btn, .btn-toolbar .btn-group, .btn-toolbar .input-group {
  float: left
}

.btn-toolbar > .btn, .btn-toolbar > .btn-group, .btn-toolbar > .input-group {
  margin-left: 5px
}

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0
}

.btn-group > .btn:first-child {
  margin-left: 0
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-group > .btn:last-child:not(:first-child), .btn-group > .dropdown-toggle:not(:first-child) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.btn-group > .btn-group {
  float: left
}

.btn-group > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0
}

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.btn-group > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.btn-group .dropdown-toggle:active, .btn-group.open .dropdown-toggle {
  outline: 0
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: 8px;
  padding-right: 8px
}

.btn-group > .btn-lg + .dropdown-toggle, .btn-group-lg.btn-group > .btn + .dropdown-toggle {
  padding-left: 12px;
  padding-right: 12px
}

.btn-group.open .dropdown-toggle {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)
}

.btn-group.open .dropdown-toggle.btn-link {
  box-shadow: none
}

.btn .caret {
  margin-left: 0
}

.btn-lg .caret, .btn-group-lg > .btn .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0
}

.dropup .btn-lg .caret, .dropup .btn-group-lg > .btn .caret {
  border-width: 0 5px 5px
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group, .btn-group-vertical > .btn-group > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%
}

.btn-group-vertical > .btn-group:before, .btn-group-vertical > .btn-group:after {
  content: " ";
  display: table
}

.btn-group-vertical > .btn-group:after {
  clear: both
}

.btn-group-vertical > .btn-group > .btn {
  float: none
}

.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
  margin-top: -1px;
  margin-left: 0
}

.btn-group-vertical > .btn:not(:first-child):not(:last-child) {
  border-radius: 0
}

.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

.btn-group-vertical > .btn-group:not(:first-child):not(:last-child) > .btn {
  border-radius: 0
}

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child, .btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical > .btn-group:last-child:not(:first-child) > .btn:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate
}

.btn-group-justified > .btn, .btn-group-justified > .btn-group {
  float: none;
  display: table-cell;
  width: 1%
}

.btn-group-justified > .btn-group .btn {
  width: 100%
}

.btn-group-justified > .btn-group .dropdown-menu {
  left: auto
}

[data-toggle="buttons"] > .btn input[type="radio"], [data-toggle="buttons"] > .btn input[type="checkbox"], [data-toggle="buttons"] > .btn-group > .btn input[type="radio"], [data-toggle="buttons"] > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate
}

.input-group[class*="col-"] {
  float: none;
  padding-left: 0;
  padding-right: 0
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0
}

.input-group .form-control:focus {
  z-index: 3
}

.input-group-addon, .input-group-btn, .input-group .form-control {
  display: table-cell
}

.input-group-addon:not(:first-child):not(:last-child), .input-group-btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) {
  border-radius: 0
}

.input-group-addon, .input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle
}

.input-group-addon {
  padding: 8px 12px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1;
  color: #333;
  text-align: center;
  background-color: #eee;
  border: 1px solid #333;
  border-radius: 0
}

.input-group-addon.input-sm, .input-group-sm > .input-group-addon, .input-group-sm > .input-group-btn > .input-group-addon.btn {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 0
}

.input-group-addon.input-lg, .input-group-lg > .input-group-addon, .input-group-lg > .input-group-btn > .input-group-addon.btn {
  padding: 10px 16px;
  font-size: 23px;
  border-radius: 0
}

.input-group-addon input[type="radio"], .input-group-addon input[type="checkbox"] {
  margin-top: 0
}

.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group-btn:last-child > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.input-group-addon:first-child {
  border-right: 0
}

.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child), .input-group-btn:first-child > .btn-group:not(:first-child) > .btn {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.input-group-addon:last-child {
  border-left: 0
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap
}

.input-group-btn > .btn {
  position: relative
}

.input-group-btn > .btn + .btn {
  margin-left: -1px
}

.input-group-btn > .btn:hover, .input-group-btn > .btn:focus, .input-group-btn > .btn:active {
  z-index: 2
}

.input-group-btn:first-child > .btn, .input-group-btn:first-child > .btn-group {
  margin-right: -1px
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
  z-index: 2;
  margin-left: -1px
}

.nav {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none
}

.nav:before, .nav:after {
  content: " ";
  display: table
}

.nav:after {
  clear: both
}

.nav > li {
  position: relative;
  display: block
}

.nav > li > a {
  position: relative;
  display: block;
  padding: 10px 15px
}

.nav > li > a:hover, .nav > li > a:focus {
  text-decoration: none;
  background-color: #069;
  color: #fff
}

.nav > li.disabled > a {
  color: #999
}

.nav > li.disabled > a:hover, .nav > li.disabled > a:focus {
  color: #999;
  text-decoration: none;
  background-color: transparent;
  cursor: not-allowed
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
  background-color: #069;
  border-color: #069
}

.nav .nav-divider {
  height: 1px;
  margin: 12.5px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.nav > li > a > img {
  max-width: none
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
  margin-top: 10px
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px
}

.nav-tabs > li > a {
  margin-right: 2px;
  line-height: 1.5;
  border: 1px solid transparent;
  border-radius: 0 0 0 0
}

.nav-tabs > li > a:hover {
  border-color: #eee #eee #ddd
}

.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
  color: #555;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default
}

.nav-pills > li {
  float: left
}

.nav-pills > li > a {
  border-radius: 0
}

.nav-pills > li + li {
  margin-left: 2px
}

.nav-pills > li.active > a, .nav-pills > li.active > a:hover, .nav-pills > li.active > a:focus {
  color: #fff;
  background-color: #069
}

.nav-stacked > li {
  float: none
}

.nav-stacked > li + li {
  margin-top: 2px;
  margin-left: 0
}

.nav-justified, .nav-tabs.nav-justified {
  width: 100%
}

.nav-justified > li, .nav-tabs.nav-justified > li {
  float: none
}

.nav-justified > li > a, .nav-tabs.nav-justified > li > a {
  text-align: center;
  margin-bottom: 5px
}

.nav-justified > .dropdown .dropdown-menu {
  top: auto;
  left: auto
}

@media (min-width: 768px) {
  .nav-justified > li, .nav-tabs.nav-justified > li {
    display: table-cell;
    width: 1%
  }

  .nav-justified > li > a, .nav-tabs.nav-justified > li > a {
    margin-bottom: 0
  }
}

.nav-tabs-justified, .nav-tabs.nav-justified {
  border-bottom: 0
}

.nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
  margin-right: 0;
  border-radius: 0
}

.nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus {
  border: 1px solid #ddd
}

@media (min-width: 768px) {
  .nav-tabs-justified > li > a, .nav-tabs.nav-justified > li > a {
    border-bottom: 1px solid #ddd;
    border-radius: 0 0 0 0
  }

  .nav-tabs-justified > .active > a, .nav-tabs.nav-justified > .active > a, .nav-tabs-justified > .active > a:hover, .nav-tabs.nav-justified > .active > a:hover, .nav-tabs-justified > .active > a:focus, .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #fff
  }
}

.tab-content > .tab-pane {
  display: none
}

.tab-content > .active {
  display: block
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.navbar {
  position: relative;
  min-height: 90px;
  margin-bottom: 0;
  border: 1px solid transparent
}

.navbar:before, .navbar:after {
  content: " ";
  display: table
}

.navbar:after {
  clear: both
}

@media (min-width: 992px) {
  .navbar {
    border-radius: 0
  }
}

.navbar-header:before, .navbar-header:after {
  content: " ";
  display: table
}

.navbar-header:after {
  clear: both
}

@media (min-width: 992px) {
  .navbar-header {
    float: left
  }
}

.navbar-collapse {
  overflow-x: visible;
  padding-right: 15px;
  padding-left: 15px;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-overflow-scrolling: touch
}

.navbar-collapse:before, .navbar-collapse:after {
  content: " ";
  display: table
}

.navbar-collapse:after {
  clear: both
}

.navbar-collapse.in {
  overflow-y: auto
}

@media (min-width: 992px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    box-shadow: none
  }

  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important
  }

  .navbar-collapse.in {
    overflow-y: visible
  }

  .navbar-fixed-top .navbar-collapse, .navbar-static-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    padding-left: 0;
    padding-right: 0
  }
}

.navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
  max-height: 340px
}

@media (max-device-width: 480px) and (orientation: landscape) {
  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 200px
  }
}

.container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
  margin-right: -15px;
  margin-left: -15px
}

@media (min-width: 992px) {
  .container > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-header, .container-fluid > .navbar-collapse {
    margin-right: 0;
    margin-left: 0
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px
}

@media (min-width: 992px) {
  .navbar-static-top {
    border-radius: 0
  }
}

.navbar-fixed-top, .navbar-fixed-bottom {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030
}

@media (min-width: 992px) {
  .navbar-fixed-top, .navbar-fixed-bottom {
    border-radius: 0
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px
}

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0
}

.navbar-brand {
  float: left;
  padding: 15px 15px;
  font-size: 23px;
  line-height: 27px;
  height: 90px
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none
}

.navbar-brand > img {
  display: block
}

@media (min-width: 992px) {
  .navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: -15px
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  margin-right: 15px;
  padding: 9px 10px;
  margin-top: 28px;
  margin-bottom: 28px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0
}

.navbar-toggle:focus {
  outline: 0
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px
}

.navbar-toggle .icon-bar + .icon-bar {
  margin-top: 4px
}

@media (min-width: 992px) {
  .navbar-toggle {
    display: none
  }
}

.navbar-nav {
  margin: 15px -15px
}

.navbar-nav > li > a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 27px
}

@media (max-width: 991px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    box-shadow: none
  }

  .navbar-nav .open .dropdown-menu > li > a, .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 15px 5px 25px
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: 27px
  }

  .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-nav .open .dropdown-menu > li > a:focus {
    background-image: none
  }
}

@media (min-width: 992px) {
  .navbar-nav {
    float: left;
    margin: 0
  }

  .navbar-nav > li {
    float: left
  }

  .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 30px
  }
}

.navbar-form {
  margin-left: -15px;
  margin-right: -15px;
  padding: 10px 15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  margin-top: 22.5px;
  margin-bottom: 22.5px
}

@media (min-width: 768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle
  }

  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle
  }

  .navbar-form .form-control-static {
    display: inline-block
  }

  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle
  }

  .navbar-form .input-group .input-group-addon, .navbar-form .input-group .input-group-btn, .navbar-form .input-group .form-control {
    width: auto
  }

  .navbar-form .input-group > .form-control {
    width: 100%
  }

  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle
  }

  .navbar-form .radio, .navbar-form .checkbox {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle
  }

  .navbar-form .radio label, .navbar-form .checkbox label {
    padding-left: 0
  }

  .navbar-form .radio input[type="radio"], .navbar-form .checkbox input[type="checkbox"] {
    position: relative;
    margin-left: 0
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0
  }
}

@media (max-width: 991px) {
  .navbar-form .form-group {
    margin-bottom: 5px
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0
  }
}

@media (min-width: 992px) {
  .navbar-form {
    width: auto;
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: none
  }
}

.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.navbar-fixed-bottom .navbar-nav > li > .dropdown-menu {
  margin-bottom: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.navbar-btn {
  margin-top: 22.5px;
  margin-bottom: 22.5px
}

.navbar-btn.btn-sm, .btn-group-sm > .navbar-btn.btn {
  margin-top: 28.5px;
  margin-bottom: 28.5px
}

.navbar-btn.btn-xs, .btn-group-xs > .navbar-btn.btn {
  margin-top: 34px;
  margin-bottom: 34px
}

.navbar-text {
  margin-top: 31.5px;
  margin-bottom: 31.5px
}

@media (min-width: 992px) {
  .navbar-text {
    float: left;
    margin-left: 15px;
    margin-right: 15px
  }
}

@media (min-width: 992px) {
  .navbar-left {
    float: left !important
  }

  .navbar-right {
    float: right !important;
    margin-right: -15px
  }

  .navbar-right ~ .navbar-right {
    margin-right: 0
  }
}

.navbar-default {
  background-color: #fff;
  border-color: #fff
}

.navbar-default .navbar-brand {
  color: #333
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #1a1a1a;
  background-color: transparent
}

.navbar-default .navbar-text {
  color: #fff
}

.navbar-default .navbar-nav > li > a {
  color: #333
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
  color: #069;
  background-color: transparent
}

.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:hover, .navbar-default .navbar-nav > .active > a:focus {
  color: #999;
  background-color: #fff
}

.navbar-default .navbar-nav > .disabled > a, .navbar-default .navbar-nav > .disabled > a:hover, .navbar-default .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent
}

.navbar-default .navbar-toggle {
  border-color: #ddd
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
  background-color: #ddd
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #fff
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: #fff;
  color: #999
}

@media (max-width: 991px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #333
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #069;
    background-color: transparent
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .active > a, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #999;
    background-color: #fff
  }

  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent
  }
}

.navbar-default .navbar-link {
  color: #333
}

.navbar-default .navbar-link:hover {
  color: #069
}

.navbar-default .btn-link {
  color: #333
}

.navbar-default .btn-link:hover, .navbar-default .btn-link:focus {
  color: #069
}

.navbar-default .btn-link[disabled]:hover, .navbar-default .btn-link[disabled]:focus, fieldset[disabled] .navbar-default .btn-link:hover, fieldset[disabled] .navbar-default .btn-link:focus {
  color: #ccc
}

.navbar-inverse {
  background-color: #222;
  border-color: #090909
}

.navbar-inverse .navbar-brand {
  color: #bfbfbf
}

.navbar-inverse .navbar-brand:hover, .navbar-inverse .navbar-brand:focus {
  color: #fff;
  background-color: transparent
}

.navbar-inverse .navbar-text {
  color: #bfbfbf
}

.navbar-inverse .navbar-nav > li > a {
  color: #bfbfbf
}

.navbar-inverse .navbar-nav > li > a:hover, .navbar-inverse .navbar-nav > li > a:focus {
  color: #fff;
  background-color: transparent
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a:focus {
  color: #fff;
  background-color: #090909
}

.navbar-inverse .navbar-nav > .disabled > a, .navbar-inverse .navbar-nav > .disabled > a:hover, .navbar-inverse .navbar-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent
}

.navbar-inverse .navbar-toggle {
  border-color: #333
}

.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  background-color: #333
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff
}

.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: #101010
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
  background-color: #090909;
  color: #fff
}

@media (max-width: 991px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu > .dropdown-header {
    border-color: #090909
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #090909
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #bfbfbf
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #fff;
    background-color: transparent
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-color: #090909
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:hover, .navbar-inverse .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #444;
    background-color: transparent
  }
}

.navbar-inverse .navbar-link {
  color: #bfbfbf
}

.navbar-inverse .navbar-link:hover {
  color: #fff
}

.navbar-inverse .btn-link {
  color: #bfbfbf
}

.navbar-inverse .btn-link:hover, .navbar-inverse .btn-link:focus {
  color: #fff
}

.navbar-inverse .btn-link[disabled]:hover, .navbar-inverse .btn-link[disabled]:focus, fieldset[disabled] .navbar-inverse .btn-link:hover, fieldset[disabled] .navbar-inverse .btn-link:focus {
  color: #444
}

.breadcrumb {
  padding: 0 0;
  margin-bottom: 27px;
  list-style: none;
  background-color: transparent;
  border-radius: 0
}

.breadcrumb > li {
  display: inline-block
}

.breadcrumb > li + li:before {
  font: normal normal normal 14px/1 FontAwesome, sans-serif;
  padding: 0 8px;
  content: "\f105"
}

.breadcrumb > .active {
  color: #999
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 27px 0;
  border-radius: 0
}

.pagination > li {
  display: inline
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 8px 12px;
  line-height: 1.5;
  text-decoration: none;
  color: #069;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px
}

.pagination > li:first-child > a, .pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.pagination > li:last-child > a, .pagination > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.pagination > li > a:hover, .pagination > li > a:focus, .pagination > li > span:hover, .pagination > li > span:focus {
  z-index: 2;
  color: #005580;
  background-color: #eee;
  border-color: #ddd
}

.pagination > .active > a, .pagination > .active > a:hover, .pagination > .active > a:focus, .pagination > .active > span, .pagination > .active > span:hover, .pagination > .active > span:focus {
  z-index: 3;
  color: #fff;
  background-color: #069;
  border-color: #069;
  cursor: default
}

.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
  color: #999;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed
}

.pagination-lg > li > a, .pagination-lg > li > span {
  padding: 10px 16px;
  font-size: 23px;
  line-height: 1.33333
}

.pagination-lg > li:first-child > a, .pagination-lg > li:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.pagination-lg > li:last-child > a, .pagination-lg > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.pagination-sm > li > a, .pagination-sm > li > span {
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.5
}

.pagination-sm > li:first-child > a, .pagination-sm > li:first-child > span {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0
}

.pagination-sm > li:last-child > a, .pagination-sm > li:last-child > span {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0
}

.pager {
  padding-left: 0;
  margin: 27px 0;
  list-style: none;
  text-align: center
}

.pager:before, .pager:after {
  content: " ";
  display: table
}

.pager:after {
  clear: both
}

.pager li {
  display: inline
}

.pager li > a, .pager li > span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px
}

.pager li > a:hover, .pager li > a:focus {
  text-decoration: none;
  background-color: #eee
}

.pager .next > a, .pager .next > span {
  float: right
}

.pager .previous > a, .pager .previous > span {
  float: left
}

.pager .disabled > a, .pager .disabled > a:hover, .pager .disabled > a:focus, .pager .disabled > span {
  color: #999;
  background-color: #fff;
  cursor: not-allowed
}

.label {
  display: inline;
  padding: .2em .6em .2em;
  font-size: 75%;
  font-weight: normal;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em
}

.label:empty {
  display: none
}

.btn .label {
  position: relative;
  top: -1px
}

a.label:hover, a.label:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer
}

.label-default {
  background-color: #fff;
  border: solid 1px #999;
  color: #666
}

a.label-default:focus, a.label-default:hover {
  background-color: #fff;
  border-color: #999;
  color: #555
}

a.label-default[href]:focus, a.label-default[href]:hover {
  background-color: #fff
}

.label-primary {
  background-color: #069
}

.label-primary[href]:hover, .label-primary[href]:focus {
  background-color: #005580
}

.label-success {
  background-color: #5cb85c
}

.label-success[href]:hover, .label-success[href]:focus {
  background-color: #449d44
}

.label-info {
  background-color: #5bc0de
}

.label-info[href]:hover, .label-info[href]:focus {
  background-color: #31b0d5
}

.label-warning {
  background-color: #f0ad4e
}

.label-warning[href]:hover, .label-warning[href]:focus {
  background-color: #ec971f
}

.label-danger {
  background-color: #d9534f
}

.label-danger[href]:hover, .label-danger[href]:focus {
  background-color: #c9302c
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 2px 7px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 1;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  background-color: #999;
  border-radius: 10px
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.btn-xs .badge, .btn-group-xs > .btn .badge {
  top: 0;
  padding: 1px 5px
}

.list-group-item.active > .badge, .nav-pills > .active > a > .badge {
  color: #069;
  background-color: #fff
}

.list-group-item > .badge {
  float: right
}

.list-group-item > .badge + .badge {
  margin-right: 5px
}

.nav-pills > li > a > .badge {
  margin-left: 3px
}

a.badge:hover, a.badge:focus {
  color: #fff;
  text-decoration: none;
  cursor: pointer
}

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee
}

.jumbotron h1, .jumbotron .h1 {
  color: inherit
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 27px;
  font-weight: 200
}

.jumbotron > hr {
  border-top-color: #d5d5d5
}

.container .jumbotron, .container-fluid .jumbotron {
  border-radius: 0;
  padding-left: 15px;
  padding-right: 15px
}

.jumbotron .container {
  max-width: 100%
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px
  }

  .container .jumbotron, .container-fluid .jumbotron {
    padding-left: 60px;
    padding-right: 60px
  }

  .jumbotron h1, .jumbotron .h1 {
    font-size: 81px
  }
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 27px;
  line-height: 1.5;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 0;
  transition: border 0.2s ease-in-out
}

.thumbnail > img, .thumbnail a > img {
  display: block;
  max-width: 100%;
  height: auto;
  margin-left: auto;
  margin-right: auto
}

.thumbnail .caption {
  padding: 9px;
  color: #333
}

a.thumbnail:hover, a.thumbnail:focus, a.thumbnail.active {
  border-color: #069
}

.alert {
  padding: 15px;
  margin-bottom: 27px;
  border: 1px solid transparent;
  border-radius: 0
}

.alert h4 {
  margin-top: 0;
  color: inherit
}

.alert .alert-link {
  font-weight: 600
}

.alert > p, .alert > ul {
  margin-bottom: 0
}

.alert > p + p {
  margin-top: 5px
}

.alert-dismissable, .alert-dismissible {
  padding-right: 35px
}

.alert-dismissable .close, .alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit
}

.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d
}

.alert-success hr {
  border-top-color: #c9e2b3
}

.alert-success .alert-link {
  color: #2b542c
}

.alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f
}

.alert-info hr {
  border-top-color: #a6e1ec
}

.alert-info .alert-link {
  color: #245269
}

.alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b
}

.alert-warning hr {
  border-top-color: #f7e1b5
}

.alert-warning .alert-link {
  color: #66512c
}

.alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442
}

.alert-danger hr {
  border-top-color: #e4b9c0
}

.alert-danger .alert-link {
  color: #843534
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }
  to {
    background-position: 0 0
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0
  }
  to {
    background-position: 0 0
  }
}

.progress {
  overflow: hidden;
  height: 27px;
  margin-bottom: 27px;
  background-color: #f5f5f5;
  border-radius: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)
}

.progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 14px;
  line-height: 27px;
  color: #fff;
  text-align: center;
  background-color: #069;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease
}

.progress-striped .progress-bar, .progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px
}

.progress.active .progress-bar, .progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite
}

.progress-bar-success {
  background-color: #5cb85c
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-info {
  background-color: #5bc0de
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-warning {
  background-color: #f0ad4e
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.progress-bar-danger {
  background-color: #d9534f
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
}

.media {
  margin-top: 15px
}

.media:first-child {
  margin-top: 0
}

.media, .media-body {
  zoom: 1;
  overflow: hidden
}

.media-body {
  width: 10000px
}

.media-object {
  display: block
}

.media-object.img-thumbnail {
  max-width: none
}

.media-right, .media > .pull-right {
  padding-left: 10px
}

.media-left, .media > .pull-left {
  padding-right: 10px
}

.media-left, .media-right, .media-body {
  display: table-cell;
  vertical-align: top
}

.media-middle {
  vertical-align: middle
}

.media-bottom {
  vertical-align: bottom
}

.media-list {
  padding-left: 0;
  list-style: none
}

.list-group {
  margin-bottom: 20px;
  padding-left: 0
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd
}

.list-group-item:first-child {
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

a.list-group-item, button.list-group-item {
  color: #555
}

a.list-group-item .list-group-item-heading, button.list-group-item .list-group-item-heading {
  color: #333
}

a.list-group-item:hover, a.list-group-item:focus, button.list-group-item:hover, button.list-group-item:focus {
  text-decoration: none;
  color: #555;
  background-color: #f5f5f5
}

button.list-group-item {
  width: 100%;
  text-align: left
}

.list-group-item.disabled, .list-group-item.disabled:hover, .list-group-item.disabled:focus {
  background-color: #eee;
  color: #999;
  cursor: not-allowed
}

.list-group-item.disabled .list-group-item-heading, .list-group-item.disabled:hover .list-group-item-heading, .list-group-item.disabled:focus .list-group-item-heading {
  color: inherit
}

.list-group-item.disabled .list-group-item-text, .list-group-item.disabled:hover .list-group-item-text, .list-group-item.disabled:focus .list-group-item-text {
  color: #999
}

.list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
  z-index: 2;
  color: #fff;
  background-color: #069;
  border-color: #069
}

.list-group-item.active .list-group-item-heading, .list-group-item.active .list-group-item-heading > small, .list-group-item.active .list-group-item-heading > .small, .list-group-item.active:hover .list-group-item-heading, .list-group-item.active:hover .list-group-item-heading > small, .list-group-item.active:hover .list-group-item-heading > .small, .list-group-item.active:focus .list-group-item-heading, .list-group-item.active:focus .list-group-item-heading > small, .list-group-item.active:focus .list-group-item-heading > .small {
  color: inherit
}

.list-group-item.active .list-group-item-text, .list-group-item.active:hover .list-group-item-text, .list-group-item.active:focus .list-group-item-text {
  color: #63fff0
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8
}

a.list-group-item-success, button.list-group-item-success {
  color: #3c763d
}

a.list-group-item-success .list-group-item-heading, button.list-group-item-success .list-group-item-heading {
  color: inherit
}

a.list-group-item-success:hover, a.list-group-item-success:focus, button.list-group-item-success:hover, button.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6
}

a.list-group-item-success.active, a.list-group-item-success.active:hover, a.list-group-item-success.active:focus, button.list-group-item-success.active, button.list-group-item-success.active:hover, button.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7
}

a.list-group-item-info, button.list-group-item-info {
  color: #31708f
}

a.list-group-item-info .list-group-item-heading, button.list-group-item-info .list-group-item-heading {
  color: inherit
}

a.list-group-item-info:hover, a.list-group-item-info:focus, button.list-group-item-info:hover, button.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3
}

a.list-group-item-info.active, a.list-group-item-info.active:hover, a.list-group-item-info.active:focus, button.list-group-item-info.active, button.list-group-item-info.active:hover, button.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3
}

a.list-group-item-warning, button.list-group-item-warning {
  color: #8a6d3b
}

a.list-group-item-warning .list-group-item-heading, button.list-group-item-warning .list-group-item-heading {
  color: inherit
}

a.list-group-item-warning:hover, a.list-group-item-warning:focus, button.list-group-item-warning:hover, button.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc
}

a.list-group-item-warning.active, a.list-group-item-warning.active:hover, a.list-group-item-warning.active:focus, button.list-group-item-warning.active, button.list-group-item-warning.active:hover, button.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede
}

a.list-group-item-danger, button.list-group-item-danger {
  color: #a94442
}

a.list-group-item-danger .list-group-item-heading, button.list-group-item-danger .list-group-item-heading {
  color: inherit
}

a.list-group-item-danger:hover, a.list-group-item-danger:focus, button.list-group-item-danger:hover, button.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc
}

a.list-group-item-danger.active, a.list-group-item-danger.active:hover, a.list-group-item-danger.active:focus, button.list-group-item-danger.active, button.list-group-item-danger.active:hover, button.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden
}

.embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%
}

.embed-responsive-4by3 {
  padding-bottom: 75%
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05)
}

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15)
}

.well-lg {
  padding: 24px;
  border-radius: 0
}

.well-sm {
  padding: 9px;
  border-radius: 0
}

.close {
  float: right;
  font-size: 27px;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
  filter: alpha(opacity=20)
}

.close:hover, .close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;
  filter: alpha(opacity=50)
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none
}

.cdk-overlay-container {
  z-index: 1100;
}

.modal-open {
  overflow: hidden
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0
}

.modal.fade .modal-dialog {
  transform: translate(0, -25%);
  transition: transform 0.3s ease-out
}

.modal.in .modal-dialog {
  transform: translate(0, 0)
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px
}

.modal-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000
}

.modal-backdrop.fade {
  opacity: 0;
  filter: alpha(opacity=0)
}

.modal-backdrop.in {
  opacity: .5;
  filter: alpha(opacity=50)
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5
}

.modal-header:before, .modal-header:after {
  content: " ";
  display: table
}

.modal-header:after {
  clear: both
}

.modal-header .close {
  margin-top: -2px
}

.modal-title {
  margin: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  padding: 15px
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5
}

.modal-footer:before, .modal-footer:after {
  content: " ";
  display: table
}

.modal-footer:after {
  clear: both
}

.modal-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5)
  }

  .modal-sm {
    width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: "Frutiger Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 14px;
  opacity: 0;
  filter: alpha(opacity=0)
}

.tooltip.in {
  opacity: .9;
  filter: alpha(opacity=90)
}

.tooltip.top {
  margin-top: -3px;
  padding: 5px 0
}

.tooltip.right {
  margin-left: 3px;
  padding: 0 5px
}

.tooltip.bottom {
  margin-top: 3px;
  padding: 5px 0
}

.tooltip.left {
  margin-left: -3px;
  padding: 0 5px
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000
}

.tooltip.top-left .tooltip-arrow {
  bottom: 0;
  right: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Frutiger Neue", Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  font-size: 18px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2)
}

.popover.top {
  margin-top: -10px
}

.popover.right {
  margin-left: 10px
}

.popover.bottom {
  margin-top: 10px
}

.popover.left {
  margin-left: -10px
}

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 0 0 0 0
}

.popover-content {
  padding: 9px 14px
}

.popover > .arrow, .popover > .arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid
}

.popover > .arrow {
  border-width: 11px
}

.popover > .arrow:after {
  border-width: 10px;
  content: ""
}

.popover.top > .arrow {
  left: 50%;
  margin-left: -11px;
  border-bottom-width: 0;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  bottom: -11px
}

.popover.top > .arrow:after {
  content: " ";
  bottom: 1px;
  margin-left: -10px;
  border-bottom-width: 0;
  border-top-color: #fff
}

.popover.right > .arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-left-width: 0;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25)
}

.popover.right > .arrow:after {
  content: " ";
  left: 1px;
  bottom: -10px;
  border-left-width: 0;
  border-right-color: #fff
}

.popover.bottom > .arrow {
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
  top: -11px
}

.popover.bottom > .arrow:after {
  content: " ";
  top: 1px;
  margin-left: -10px;
  border-top-width: 0;
  border-bottom-color: #fff
}

.popover.left > .arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25)
}

.popover.left > .arrow:after {
  content: " ";
  right: 1px;
  border-right-width: 0;
  border-left-color: #fff;
  bottom: -10px
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: table
}

.clearfix:after {
  clear: both
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.hide {
  display: none !important
}

.show {
  display: block !important
}

.invisible {
  visibility: hidden
}

.text-hide {
  font-size: 0;
  line-height: 0;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0
}

.hidden {
  display: none !important
}

.affix {
  position: fixed
}

.visible-xs {
  display: none !important
}

.visible-sm {
  display: none !important
}

.visible-md {
  display: none !important
}

.visible-lg {
  display: none !important
}

.visible-xs-block, .visible-xs-inline, .visible-xs-inline-block, .visible-sm-block, .visible-sm-inline, .visible-sm-inline-block, .visible-md-block, .visible-md-inline, .visible-md-inline-block, .visible-lg-block, .visible-lg-inline, .visible-lg-inline-block {
  display: none !important
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important
  }

  table.visible-xs {
    display: table !important
  }

  tr.visible-xs {
    display: table-row !important
  }

  th.visible-xs, td.visible-xs {
    display: table-cell !important
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important
  }

  table.visible-sm {
    display: table !important
  }

  tr.visible-sm {
    display: table-row !important
  }

  th.visible-sm, td.visible-sm {
    display: table-cell !important
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important
  }

  table.visible-md {
    display: table !important
  }

  tr.visible-md {
    display: table-row !important
  }

  th.visible-md, td.visible-md {
    display: table-cell !important
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important
  }

  table.visible-lg {
    display: table !important
  }

  tr.visible-lg {
    display: table-row !important
  }

  th.visible-lg, td.visible-lg {
    display: table-cell !important
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important
  }
}

.visible-print {
  display: none !important
}

@media print {
  .visible-print {
    display: block !important
  }

  table.visible-print {
    display: table !important
  }

  tr.visible-print {
    display: table-row !important
  }

  th.visible-print, td.visible-print {
    display: table-cell !important
  }
}

.visible-print-block {
  display: none !important
}

@media print {
  .visible-print-block {
    display: block !important
  }
}

.visible-print-inline {
  display: none !important
}

@media print {
  .visible-print-inline {
    display: inline !important
  }
}

.visible-print-inline-block {
  display: none !important
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important
  }
}

@media print {
  .hidden-print {
    display: none !important
  }
}

img.centered, .aligncenter, div.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px
}

img.alignright {
  margin: 0 0 12px 12px;
  display: inline
}

img.alignleft {
  margin: 0 12px 12px 0;
  display: inline
}

.alignright {
  float: right
}

.alignleft {
  float: left
}

.wp-caption {
  text-align: center
}

.wp-caption.alignright {
  margin: 0 0 12px 12px
}

.wp-caption.alignleft {
  margin: 0 12px 12px 0
}

.wp-caption p.wp-caption-text {
  font-size: 14px;
  margin: 0;
  padding-top: 5px
}

.text-xs-left {
  text-align: left
}

.text-xs-right {
  text-align: right
}

.text-xs-center {
  text-align: center
}

.text-xs-justify {
  text-align: justify
}

.header-xs-vertical-center {
  margin-top: 25px
}

.header-vertical-center {
  margin-top: 25px
}

.pull-left-xs {
  float: left !important
}

.pull-right-xs {
  float: right !important
}

@media (min-width: 768px) {
  .text-sm-left {
    text-align: left
  }

  .text-sm-right {
    text-align: right
  }

  .text-sm-center {
    text-align: center
  }

  .text-sm-justify {
    text-align: justify
  }

  .header-sm-vertical-center {
    margin-top: 25px
  }

  .pull-left-sm {
    float: left !important
  }

  .pull-right-sm {
    float: right !important
  }
}

@media (min-width: 992px) {
  .text-md-left {
    text-align: left
  }

  .text-md-right {
    text-align: right
  }

  .text-md-center {
    text-align: center
  }

  .text-md-justify {
    text-align: justify
  }

  .header-md-vertical-center {
    margin-top: 25px
  }

  .pull-left-md {
    float: left !important
  }

  .pull-right-md {
    float: right !important
  }
}

@media (min-width: 1200px) {
  .text-lg-left {
    text-align: left
  }

  .text-lg-right {
    text-align: right
  }

  .text-lg-center {
    text-align: center
  }

  .text-lg-justify {
    text-align: justify
  }

  .header-lg-vertical-center {
    margin-top: 25px
  }

  .pull-left-lg {
    float: left !important
  }

  .pull-right-lg {
    float: right !important
  }
}

.nf-form-cont .ninja-forms-req-symbol, .nf-form-cont .nf-error-msg {
  color: #a94442
}

.nf-form-cont .nf-field-element input, .nf-form-cont .nf-field-element select {
  height: 45px
}

.nf-form-cont .nf-field-element input.input-lg, .nf-form-cont .nf-field-element .input-group-lg > input.form-control, .nf-form-cont .nf-field-element .input-group-lg > input.input-group-addon, .nf-form-cont .nf-field-element .input-group-lg > .input-group-btn > input.btn, .nf-form-cont .nf-field-element select.input-lg, .nf-form-cont .nf-field-element .input-group-lg > select.form-control, .nf-form-cont .nf-field-element .input-group-lg > select.input-group-addon, .nf-form-cont .nf-field-element .input-group-lg > .input-group-btn > select.btn {
  height: 63px
}

.nf-form-cont .nf-field-element input.input-sm, .nf-form-cont .nf-field-element .input-group-sm > input.form-control, .nf-form-cont .nf-field-element .input-group-sm > input.input-group-addon, .nf-form-cont .nf-field-element .input-group-sm > .input-group-btn > input.btn, .nf-form-cont .nf-field-element select.input-sm, .nf-form-cont .nf-field-element .input-group-sm > select.form-control, .nf-form-cont .nf-field-element .input-group-sm > select.input-group-addon, .nf-form-cont .nf-field-element .input-group-sm > .input-group-btn > select.btn {
  height: 33px
}

.nf-form-cont .nf-field-element input[type='checkbox'], .nf-form-cont .nf-field-element input[type='radio'], .nf-form-cont .nf-field-element select[multiple] {
  height: auto
}

.btn-default-gray {
  color: #555;
  background-color: #fff;
  border-color: #555
}

.btn-default-gray:focus, .btn-default-gray.focus {
  color: #069;
  background-color: #fff;
  border-color: #069
}

.btn-default-gray:hover {
  color: #069;
  background-color: #fff;
  border-color: #069
}

.btn-default-gray:active, .btn-default-gray.active, .open > .btn-default-gray.dropdown-toggle {
  color: #069;
  background-color: #fff;
  border-color: #069;
  background-image: none
}

.btn-default-gray:active:hover, .btn-default-gray:active:focus, .btn-default-gray:active.focus, .btn-default-gray.active:hover, .btn-default-gray.active:focus, .btn-default-gray.active.focus, .open > .btn-default-gray.dropdown-toggle:hover, .open > .btn-default-gray.dropdown-toggle:focus, .open > .btn-default-gray.dropdown-toggle.focus {
  color: #069;
  background-color: #fff;
  border-color: #069
}

.btn-default-gray.disabled:hover, .btn-default-gray.disabled:focus, .btn-default-gray.disabled.focus, .btn-default-gray[disabled]:hover, .btn-default-gray[disabled]:focus, .btn-default-gray[disabled].focus, fieldset[disabled] .btn-default-gray:hover, fieldset[disabled] .btn-default-gray:focus, fieldset[disabled] .btn-default-gray.focus {
  background-color: #fff;
  border-color: #555
}

.btn-default-gray .badge {
  color: #fff;
  background-color: #555
}

.btn-default-gray.btn.active, .btn-default-gray.btn:active {
  box-shadow: none
}

.btn-default-inverted {
  color: #fff;
  border-color: #069
}

.btn-default-inverted:focus, .btn-default-inverted.focus {
  color: #fff;
  background-color: #005580;
  border-color: #fff
}

.btn-default-inverted:hover {
  color: #fff;
  background-color: #f5f5f5;
  border-color: #069
}

.btn-default-inverted:active, .btn-default-inverted.active, .open > .btn-default-inverted.dropdown-toggle {
  color: #fff;
  background-color: #005580;
  border-color: #fff;
  background-image: none
}

.btn-default-inverted:active:hover, .btn-default-inverted:active:focus, .btn-default-inverted:active.focus, .btn-default-inverted.active:hover, .btn-default-inverted.active:focus, .btn-default-inverted.active.focus, .open > .btn-default-inverted.dropdown-toggle:hover, .open > .btn-default-inverted.dropdown-toggle:focus, .open > .btn-default-inverted.dropdown-toggle.focus {
  color: #fff;
  background-color: #005580;
  border-color: #fff
}

.btn-default-inverted.disabled:hover, .btn-default-inverted.disabled:focus, .btn-default-inverted.disabled.focus, .btn-default-inverted[disabled]:hover, .btn-default-inverted[disabled]:focus, .btn-default-inverted[disabled].focus, fieldset[disabled] .btn-default-inverted:hover, fieldset[disabled] .btn-default-inverted:focus, fieldset[disabled] .btn-default-inverted.focus {
  background-color: #069;
  border-color: #fff
}

.btn-default-inverted .badge {
  color: #069;
  background-color: #fff
}

.btn-default-inverted.btn.active, .btn-default-inverted.btn:active {
  box-shadow: none
}

small a, .small a {
  color: #069
}

p.small {
  margin-bottom: 8px
}

.breadcrumb li.active a {
  color: #999
}

.dropup .caret {
  border-bottom: 0;
  border-top: 4px dashed;
  border-top: 4px solid \9
}

.nav-tabs > li > a, .nav-tabs > li > a:hover {
  margin-right: 2px;
  line-height: 1.5;
  color: #fff;
  border: 1px solid #ddd;
  border-radius: 0 0 0 0
}

.alert p {
  margin-bottom: 0
}

.label-primary-gray {
  background-color: #eee;
  color: #999
}

a.label-primary-gray:focus, a.label-primary-gray:hover {
  background-color: #ccc;
  color: #555
}

button.label-primary-gray {
  margin-right: 5px;
}

.dl-horizontal {
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
  overflow: hidden
}

.dl-horizontal dt:first-of-type, .dl-horizontal dd:first-of-type {
  border-top-width: 0
}

.dl-horizontal dt {
  border-top: 1px solid #eee;
  padding-top: 5px;
  margin-top: 5px
}

.dl-horizontal dd ul {
  margin-bottom: 0
}

@media (min-width: 992px) {
  .dl-horizontal dt {
    width: 200px;
    text-align: left;
    padding-right: 5px
  }

  .dl-horizontal dd {
    margin-left: 200px;
    border-top: 1px solid #eee;
    padding-top: 5px;
    margin-top: 5px
  }
}

.embed-container {
  max-width: 650px
}

.break-word {
  word-wrap: break-word
}

.top-buffer {
  margin-top: 40px
}

.bottom-buffer {
  margin-bottom: 40px
}

.inverted-container a.btn {
  color: #069
}

#main-navigation {
  font-size: 20px;
  font-weight: 600;
  border-bottom: 5px solid #dc0018;
}

#main-navigation.navbar-default .navbar-nav > li.search > a {
  color: #999
}

#main-navigation.navbar-default .navbar-nav > li.search > a:focus, #main-navigation.navbar-default .navbar-nav > li.search > a:hover {
  color: #069
}

#main-navigation h1 {
  margin: 0
}

#main-navigation .navbar-brand img {
  width: 216px;
  height: 55px;
}

#main-navigation .navbar-toggle {
  margin-top: 30px
}

@media (max-width: 992px) {
  #main-navigation .navbar-toggle {
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 4px
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  #main-navigation {
    font-size: 18px
  }

  #main-navigation .nav > li > a {
    padding-left: 10px;
    padding-right: 10px
  }
}

#main-navigation .dropdown-menu > li > a {
  line-height: 2
}

.page-header h1 {
  margin-top: 10px;
  margin-bottom: 5px
}

.page-header .breadcrumb {
  margin-bottom: 0
}

.page-header .breadcrumb a {
  color: #fff
}

.page-header .breadcrumb li.active {
  color: #333
}

.page-header .breadcrumb li.active a {
  color: #333
}

.contribute h2 {
  /*margin-top: 5px;*/
  margin-bottom: 5px
}

.contribute .btn {
  margin: 5px 10px 5px 0
}

.contribute .btn.last {
  margin-right: 0
}

.contribute .buttons {
  margin-top: 20px
}

@media (max-width: 992px) {
  .contribute .buttons {
    margin-top: 0
  }
}

.page-footer {
  color: #fff;
  background: #2d2d2d;
  box-shadow: 0 1000px 0 1000px #333;
  padding-top: 40px
}

.page-footer small, .page-footer .small {
  color: #fff
}

.page-footer a {
  color: #fff
}

.page-footer a:hover {
  color: #d9d9d9
}

.page-footer a.btn:hover {
  color: #fff
}

.page-footer h2 {
  font-size: 24px;
  margin-top: 15px;
}

.page-footer .statsnumber {
  font-size: 40px;
  line-height: 46px
}

.page-footer li {
  margin-bottom: 12px
}

.page-footer #logos img {
  vertical-align: top;
  margin-right: 30px;
  margin-bottom: 30px
}

.page-footer #logos img.logo-confederation {
  width: 245px
}

.page-footer #logos img.logo-egovernment {
  width: 184px
}

.page-footer-navigation {
  color: #fff;
  background: #242424;
  box-shadow: 0 1000px 0 1000px #242424;
  padding-top: 40px;
  padding-bottom: 40px
}

.page-footer-navigation .container {
  display: flex;
  align-items: center
}

.page-footer-navigation .footer-navigation {
  padding-left: 40px;
  flex: 1
}

.page-footer-navigation .footer-navigation ul {
  margin-bottom: 0
}

.page-footer-navigation .footer-navigation .list-inline > li {
  padding: 5px 10px
}

@media (max-width: 767px) {
  .page-footer-navigation .container {
    align-items: start
  }

  .page-footer-navigation .list-inline > li {
    display: list-item
  }
}

.page-footer-navigation a {
  color: #fff
}

.page-footer-navigation a:hover {
  color: #d9d9d9
}

.page-footer-navigation a.btn:hover {
  color: #fff
}

.page-footer-navigation .btn-default {
  color: #fff;
  background-color: #242424;
  border-color: #fff
}

.page-footer-navigation ul.list-inline {
  margin-bottom: 0
}

.page-footer-navigation .dropdown-menu li {
  margin-bottom: 0
}

.front-header {
  padding: 0
}

.front-header h1 {
  margin-bottom: 20px
}

.front-header a {
  color: #fff
}

.front-header a:hover {
  color: #dfeceb
}

.front-header a.btn {
  color: #fff
}

.front-header .container {
  padding-top: 13px;
  padding-bottom: 13px;
  background-repeat: no-repeat;
  background-position: right center
}

@media (max-width: 992px) {
  .front-header .container {
    padding-top: 40px;
    padding-bottom: 40px;
    background-position: center bottom
  }
}

.front-header .headline {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px
}

.front-header .headline #opendata-count {
  width: 100%;
  margin-top: -10px;
  font-size: 120px;
  line-height: 100%
}

@media (max-width: 992px) {
  .front-header .headline #opendata-count {
    margin-top: 20px
  }
}

.front-header .headline .title {
  line-height: 28px
}

#explore .category {
  /*margin-top: 15px;*/
  margin-bottom: 15px;
  font-size: 20px
}

#explore .category small {
  font-size: 18px;
  margin-left: 5px
}

#how .fa {
  font-size: 40px;
  margin-right: 5px
}

.search-form h2 {
  font-size: 26px
}

.facet-filter {
  margin-top: 27px
}


.facet-filter h2 {
  font-size: 20px
}

.facet-filter span {
  margin-left: 3px
}

.facet-filter .nav > li > a {
  border-left: 2px solid transparent;
  padding: 5px 10px
}

.facet-filter .nav > li > a:focus, .facet-filter .nav > li > a:hover, .facet-filter .nav > li.active > a {
  color: #069;
  border-left-color: #069;
  background-color: #eee
}

.facet-filter .nav > li > a:focus::after, .facet-filter .nav > li > a:hover::after, .facet-filter .nav > li.active > a::after {
  content: '+';
  float: right;
  color: #fff;
  background-color: #069;
  border-radius: 18px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin-top: 5px;
  text-align: center
}

.facet-filter .nav > li.active > a::after {
  content: '\d7'
}

.facet-filter .res_rights img {
  height: 50px
}

.facet-filter .res_rights.nav > li > a:focus::after, .facet-filter .res_rights.nav > li > a:hover::after, .facet-filter .res_rights.nav > li.active > a::after {
  margin-top: 16px
}

.reset-search {
  display: block;
  text-align: right
}

@media (min-width: 992px) {
  .facet-collapse.collapse {
    display: block !important;
    height: auto !important
  }
}

.facet-toggle .fa {
  float: right
}

@media (min-width: 992px) {
  .facet-toggle {
    display: none
  }
}

@media (max-width: 992px) {
  .facet-header {
    display: none
  }
}

.search-result-title h2 {
  float: left;
  margin-right: 15px
}

.search-result-title .sortby {
  text-align: right
}

@media (max-width: 992px) {
  .search-result-title h2 {
    float: none
  }

  .search-result-title .sortby {
    text-align: left
  }
}

.list-inline.dataset-resources, .list-inline.tags {
  margin-left: 0
}

.list-inline.dataset-resources li, .list-inline.tags li {
  padding: 0
}

.list-inline.dataset-resources li.active a, .list-inline.tags li.active a {
  background-color: #ccc;
  color: #555
}

li.dataset-item {
  position: relative;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid #eee 1px
}

li.dataset-item h2 {
  margin-top: 10px;
}

li.dataset-item h2 a:visited {
  color: #880096
}

.terms img {
  height: 60px
}

.resource-list li {
  overflow: hidden;
  border-bottom: solid #eee 1px;
  padding: 10px 0
}

.resource-list a:visited:not(.btn) {
  color: #880096
}

.resource-list .actions a {
  margin-left: 10px
}

.resource-list .heading {
  margin-left: 5px;
  margin-right: 5px
}

.additional-info th.header-col {
  width: 200px
}

#discourse-embed-frame {
  min-height: 50px
}

#organizations-list li a {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #eee
}

#organizations-list li a:hover {
  background-color: #eee
}

.organization-hierarchy li.organization {
  display: none
}

.organization-hierarchy li.organization.childmatch {
  display: list-item
}

.organization-hierarchy li.organization.match {
  display: list-item
}

.organization-hierarchy li.organization.match .organization-row .highlight {
  background-color: #e4eeed
}

.organization-hierarchy.empty-search li.organization {
  display: list-item
}

.organization-hierarchy.empty-search li.organization .organization-row a .highlight {
  background-color: transparent
}

.description img.logo {
  display: block;
  height: auto;
  max-height: 250px;
  width: auto;
  max-width: 30%;
  margin-bottom: 20px;
  margin-left: 20px
}

@media (max-width: 991px) {
  .description img.logo {
    max-height: none;
    max-width: 100%;
    margin-left: 0
  }
}

#group-list li {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px
}

#group-list h2 {
  margin-bottom: 5px
}

.js-hide {
  display: none
}

.ui-autocomplete .ui-menu-item a, .ui-autocomplete .ui-menu-item a:active, .ui-autocomplete .ui-menu-item a:focus, .ui-autocomplete .ui-menu-item a:hover, .ui-autocomplete .ui-menu-item a:visited {
  display: block;
  text-decoration: none;
  padding: 8px 1em 8px 1em
}

.ui-autocomplete .ui-state-active {
  background-color: #069;
  border-color: #eee
}

.media-grid {
  list-style: none;
  padding-top: 15px
}

.media-item {
  position: relative;
  padding: 15px;
  margin: 0 15px 15px 15px;
  background-color: white;
  border-radius: 3px
}

.media-item span.count {
  color: #999
}

.media-item .media-image {
  margin-bottom: 5px
}

.media-item .media-edit {
  opacity: 0;
  position: absolute;
  right: 15px;
  bottom: 15px;
  transition: opacity 0.2s ease-in
}

.media-item:hover {
  z-index: 1
}

.media-item:hover .media-edit {
  opacity: 1
}

.media-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #dddddd;
  overflow: hidden;
  transition: all 0.2s ease-in;
  border-radius: 3px
}

.media-view:hover, .media-view.hovered {
  border-color: #069;
  box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1)
}

.media-view:hover .banner, .media-view.hovered .banner {
  background-color: #069
}

.media-view span {
  display: none
}

.media-view .banner {
  display: block;
  background-color: #b7b7b7;
  transition: background-color 0.2s ease-in
}

.media-image {
  border-radius: 4px
}

.media-image img {
  min-width: 100%
}

.media-heading {
  font-size: 18px;
  line-height: 1.3;
  margin: 0 0 5px;
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto
}

.media-overlay {
  position: relative;
  min-height: 35px
}

.media-overlay .media-heading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 12px 10px;
  margin: 0;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 13px;
  color: #fff;
  z-index: 1;
  border-radius: 0 0 3px 3px
}

.media-overlay .media-image {
  float: none;
  display: block;
  margin-right: 0
}

.media-item.is-expander .truncator-link {
  transition: opacity 0.2s ease-in;
  position: absolute;
  z-index: 10;
  left: 15px;
  bottom: 15px;
  opacity: 0
}

.media-item.is-expander:hover {
  padding-bottom: 35px
}

.media-item.is-expander:hover .truncator-link {
  opacity: 1
}

.wide .media-item {
  width: 186px
}

.container li {
  list-style: none
}
