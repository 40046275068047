@import "@ng-select/ng-select/themes/default.theme.css";
@import "@angular/cdk/overlay-prebuilt.css";

body {
  margin: 0;
}

.clear-margin-top {
  margin-top: 0 !important;
}

.clear-margin-bottom {
  margin-bottom: 0 !important;
}

.clear-margin-left {
  margin-left: 0 !important;
}

.clear-padding-left {
  padding-left: 0 !important;
}

.clear-padding-right {
  padding-right: 0 !important;
}

ul.tabmenu {
  list-style: none;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 0 !important;
  padding: 0;
  border-bottom: 1px solid #ddd;
}

ul.tabmenu li {
  display: inline-block;
  margin-bottom: -1px;
  background-color: #f5f5f5;
  width: 25%;
}

ul.tabmenu>li>a {
  position: relative;
  border: 1px solid #ccc;
}

ul.tabmenu>li>a:hover {
  color: #dc0018;
  text-decoration: none;
}

ul.tabmenu>li+li>a {
  border-left-width: 0!important;
}

ul.tabmenu li.autoWidth {
  width: auto !important;
}

ul.tabmenu li.active a {
  color: #dc0018;
  border: 1px solid #ccc;
  border-bottom-color: transparent;
}

ul.tabmenu>li.active>a::before, ul.tabmenu>li+li>a:hover::before {
  content: '';
  display: block;
  height: 3px;
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  background-color: #dc0018;
}

ul.tabmenu li.active span.badge.badge-search-tab {
  background-color: #fff !important;
  border-style: solid;
  border-width: thin;
  color: #333 !important;
}

ul.tabmenu li a {
  color: #333;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  padding: 10px 15px;
}

div.tabmenu-content {
  padding-top: 1em;
}

div.tabmenu-content-freigabe, div.tabmenu-content-report {
  font-size: 0.8em;
}

div.tabmenu-content-freigabe h2, div.tabmenu-content-report h2 {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
}

div.tabmenu-content-freigabe h3, div.tabmenu-content-report h3 {
  font-size: 12px;
  margin-top: 13.5px;
}

div.tabmenu-content-freigabe section, div.tabmenu-content-report section {
  margin-top: 13.5px;
}

div.freigabe-filter, div.adura-report {
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border: 1px solid grey;
}

label.freigabe-type-filter {
  width: 100%;
  padding-left: 10px;
  cursor: pointer;
  border-radius: 3px;
  line-height: 1.8em;
}

label.freigabe-type-filter.chosen {
  background: #006699;
  color: white;
}

.freigabe-type-filter.warning-icon {
  color: #a94442;
}

div.freigabe-type-req-fields {
  padding-top: 10px;
  margin-left: -17px;
}

.freigabe-checkbox-label {
  flex-grow: 1;
  justify-content: flex-start;
  margin-right: 0.6em;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  overflow: hidden;
  font-size: 12px !important;
  padding-top: 2px;
}

input[type="checkbox"].freigabe-filter-checkbox + label::before {
  width: 12px;
  height: 12px;
  border-radius: 20%;
  border: 1px solid #999;
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}

div.tabmenu-content-framed {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}

.admin-content {
  padding-bottom: 20px;
}

.adura-admin-content ul.tabmenu li {
  width: 14.28%;
}

div.admin-divider {
  margin-top: 2em;
  margin-bottom: 2em;
  border-top: 1px #ccc solid;
}

.adura-main ul.tabmenu li {
  width: 33.3%;
}

p.info-date {
  display:inline;
  padding-left: 15px;
  font-size: 0.8em;
  color: lightgrey;
}

.btn-sm {
  margin-top: 3px;
  border: 1px solid;
  display: inline-block;
}

.version-info {
  color: #888;
  padding-left: 2px;
}

.dropdown {
  cursor: pointer;
}

div.doc-version-name {
  padding-left: 15px;
  cursor: pointer;
}

.doc-version-aenderungskommentar {
  padding-left: 15px;
}

@media (max-width: 768px) {
  .doc-version-author{
    margin-left: 16.7%
  }

  .doc-version-date {
    margin-left: 16.7%
  }
}

.doc-thema ul {
  padding-left: 20px;
}

.doc-thema ul li {
  list-style: disc;
}

a.actanova-link {
  margin-right: 30px;
}

nav.reset-search a {
  cursor: pointer;
}

.search-doc-date {
  margin-bottom: 1.66666%;
}

.label {
  margin-right: 3px;
}

.button-domain {
  margin-right: 3px;
}

.button-domain-doc {
  padding: 1px 4px !important;
  margin: 0 !important;
}

.front-header div.admin {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

div.admin {
  margin-bottom: 5px;
}

div.admin button {
  margin-top: 0;
}

p.admin {
  margin: 10px 0 0 0;
  font-size: 0.9em;
}

p.admin-warning {
  margin: 10px 0 0 0;
  font-size: 1.0em;
  color: #dc0018;
}

span.doc-status {
  display: inline-block;
  width: 55px;
}

span.doc-status-gueltig {
  border: 1px solid #069;
  background-color: #069;
  color: white;
}

.thema table {
  border-collapse: collapse;
  margin-bottom: 13.5px;
}

.thema table, th, td {
  border: 1px solid black;
}

.thema td {
  vertical-align: top;
  text-align: left;
  padding: 0 5px;
}

.thema ul li {
  list-style: disc;
}

.thema ul, ol {
  margin-bottom: 13.5px;
}

div.thema a:hover, a:focus {
  text-decoration: underline
}

div.item-content-name{
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 13.5px;
}

a.item-content-name {
  word-wrap: break-word;
}

.item-content-tags {
  margin-bottom: 13.5px;
}

.item-comment-format-type {
  margin-left: 5px;
}

ul.themainfo-list {
  padding-left: 20px;
  padding-right: 20px;
}

ul.themainfo-list li {
  list-style: disc !important;
}

a.thema-show-all-links {
  cursor: pointer;
  color: black;
}

.responsive {
  max-width: 100%;
  height: auto;
}

.comment-text {
  display: inline;
}

div.log-period {
  display: inline-block;
}

p.log-period {
  display: inline;
}

select.log-period {
  width: 30%;
  min-width: 150px;
  height: 30px;
  font-size: 10pt;
  float: right;
  display: inline;
  margin: 0;
  transition: all .2s ease-in-out;
}

input.admin-settings {
  width: 100%;
  height: 30px;
  font-size: 14px;
  margin-top: 0;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid gainsboro;
}

.setting-delete {
  background-color: #FFF0F0;
}

textarea.admin-settings {
  width: 100%;
  height: 30px;
  min-height: 30px;
  font-size: 14px;
  margin-top: 0;
  padding: 4px 5px 3px;
  resize: none;
  border: 1px solid gainsboro;
}

p.admin-settings {
  margin: 0;
  font-size: 14px;
}

p.admin-checks {
  margin: 5px 0;
  font-size: 14px;
}

p.title {
  font-weight: bold;
}

.title {
  font-weight: bold;
}

p.job {
  margin-bottom: 5px;
}

.spinner {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.7);
  z-index:9999;
  display:flex;
}

.splash-screen-spinner {
  margin: auto;
}

button.admin-settings {
  margin-right: 4px;
  margin-bottom: 2px;
}

div.row.admin {
  margin-top: 15px;
}

i.job-ok {
  font-size: 2em;
  color: green;
}

i.job-nok {
  font-size: 2em;
  color: #dc0018;
}

div.job-nok {
  border-radius: 3px;
  background-color: #F8D5DB;
}

ul.thema-searchlink-results {
  padding: 10px 30px;
  border: 1px dashed black;
  border-radius: 5px;
  background-color: #f5f5f5;
}

.thema-searchlink-icon {
  font-size: 13px;
}

.modal-backdrop.show {
  opacity: .5;
}

.fade.show {
  opacity: 1;
}

.comment-create-text {
  resize: vertical;
}

button.new-comment {
  margin-top: 10px;
}

.disclaimer {
  background-color: #eee;
}

.modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 0;
}

.modal-dialog {
  width: auto;
  min-width: 0;
  display: table;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.document-preview-modal-body {
  height: 80vh;
  overflow: auto;
}

.document-preview-width-50 {
  min-width: 50vw;
}

.document-preview-carousel {
  max-width: 90vw;
}

.document-preview-image {
  height: 75vh;
  display: block;
  margin: 0 auto;
}

.document-preview-html {
  height: 75vh;
  width: 100%;
  border: 1px solid #e5e5e5;
}

.document-preview-icon {
  color: #069;
  font-size: 20px;
  line-height: 25px;
  margin: 0 10px 0 10px;
  float: right;
}

.document-preview-icon:hover {
  cursor: pointer;
}

.document-preview-icon-left {
  color: #069;
  font-size: 20px;
  line-height: 25px;
  margin: 0 10px 0 10px;
  float: left;
}

.document-preview-icon-left:hover {
  cursor: pointer;
}

.document-search-term-in-content {
  color: #069;
  font-size: 20px;
  line-height: 25px;
  margin: 0 10px 0 10px;
}

/* https://github.com/twbs/bootstrap/blob/v4-dev/dist/css/bootstrap.css */
.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: rgba(157, 157, 157, 0.62);
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.62);
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

.carousel .carousel-indicators li {
  background-color: #fff;
  background-color: rgba(70, 70, 70, 0.25);
}

.carousel .carousel-indicators .active {
  background-color: #444;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

/* End Bootstrap Carousel CSS */

@media (max-width: 425px) {
  .modal-dialog {
    width: 100% !important;
    height: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5)
  }
}

.application-brand {
  display: -webkit-box;
  display: flex;
  -webkit-box-flex: 1;
  flex: auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  min-width: 0;
  padding: 8px 15px;
  -webkit-transition: padding .6s ease-in-out;
  transition: padding .6s ease-in-out;
  height: 90px
}

.application-brand-logo img{
  height:55px;
}

.application-brand-app-title {
  display: block;
  -webkit-box-flex: 1;
  flex: 1;
  font-size: 22pt;
  padding-left: 15px;
  border-left: 1px solid #f5f5f5;
  max-height: 55px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.application-brand-link {
  color: black;
  font-weight: 300;
}

.flex-container {
  display: flex;
}

.search-reset {
  width: 63px;
  height: 63px;
  color: #333;
  background-color: #E1E1E1;
  border-color: #069 !important;
}

.search-reset:hover {
  background-color: #E5F1FB;
  color: #333 !important;
}

.badge-search-tab {
  background-color: #f5f5f5 !important;
  color: #333 !important;
}

.search-order-by {
  margin-right: 1.66666%
}

.doc-item-description {
  line-height: 1.5em;
  max-height: 3em;
  width: 100%;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.doc-item-format {
  margin-left: 7px;
  font-size: 60% !important;
  color: #999;
}

.search-button {
  pointer-events: auto !important;
  cursor: pointer;
}

.filter-checkbox {
  border-radius: 20%;
  border: 1px solid #999;
  background: #fff;
  color: #999;
  margin-top: 8px !important;
}

.filter-checkbox-label {
  flex-grow: 1;
  justify-content: flex-start;
  margin-right: 0.6em;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  overflow: hidden;
  font-size: 16px !important;
  padding-top: 2px;
}

.filter-checkbox-number {
  min-width: 25px;
  text-align: right;
}

input.filter-checkbox-date {
  float: right;
}

.filter-more-less {
  padding-right: 10px;
}

.filter-tag {
  background-color: #eee;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-right: 2px;
  padding-left: 0.6em !important;
  padding-right: 0.6em !important;
  padding-bottom: 3px !important;
  line-height: 1.2;
  border-radius: .25em;
}

.filter-tag-label {
  color: #999;
  font-size: 75%;
}

li.filter-tag:focus a, li.filter-tag:hover a{
  color: #555;
}

li.filter-tag:focus, li.filter-tag:hover, li.filter-tag.active {
  background-color: #ccc;
}

a.filter-tag-label:focus,  a.filter-tag-label:hover {
  color: #999;
}

input[type="checkbox"]:checked+label::before {
  box-shadow: inset 0 0 0 3px #fff;
  background-color: #dc0018;
}

input[type="checkbox"] + label::before {
  width: 15px;
  height: 15px;
  border-radius: 20%;
  border: 1px solid #999;
  background-color: #fff;
  display: block;
  content: "";
  float: left;
  margin-right: 5px;
  margin-top: 4px;
}

.title-icon {
  margin-right: 4px;
  margin-bottom: 6px !important;
}

i.link {
  transform: rotate(90deg);
}

.back-button {
  cursor: pointer;
  margin-top: 0 !important;
}

.filter-element-background {
  color: #ccc;
}

button.button-loadmore {
  margin-top: 30px;
}

.search-result-box {
  padding: 15px;
}

.search-result-box mark {
  padding: 0;
  background-color: rgba(77, 182, 255, 0.18);
}

.favorite-tiles {
  position: relative;
  margin: 5px 0 0;
}

.app-favorite-tile {
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fit, 45px);
  grid-template-rows: repeat(auto-fit, 60px);
  grid-gap: 12px;
}

.favorite-tile {
  display: block;
  grid-column: span 4;
  grid-row: span 2;
  border: 1px solid #069;
  border-radius: 4px;
  width: 215px;
  height: 110px;
  position: relative;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-width: none !important;
}

.favorite-tile div.title {
  width: 100%;
  height: 80%;
  position: absolute;
  top: 23px;
  z-index: 5;
  line-height: 1.2;
  overflow: hidden;
  cursor: pointer;
}

.favorite-tile span.title {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  line-height: 1.2;
  padding: 0 10px;
  overflow: hidden;
}

.favorite-tile .notification {
  height: 32px;
  line-height: 32px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0 10px 5px;
  z-index: 2;
}

.favorite-tile .type-icon {
  position: absolute;
  top: 2px;
  left: 10px;
  font-size: 13px;
  z-index: 2;
  color: #666;
}

.favorite-tile .icons {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  z-index: 2;
}

summary.favorite-category-title {
  font-size: 18px;
  margin-top: 10px;
  font-weight: 600;
  line-height: 1.1;
}

summary.favorite-category-title::marker {
  float: left;
}

summary>.favorite-category-title-bar {
  width: 250px;
  display: inline-block;
}

summary>.favorite-category-title-bar>.icons {
  float: right;
}

div.favorite-category-container>details {
  padding: 10px 0 0 0;
  border: none;
  background-color: white;
}

div.favorite-category-container>details>.app-favorite-tile>div.favorite-tile {
  margin-top: 10px;
}

.ng-select.favorite-category-select>.ng-select-container {
  border: 1px solid black;
  border-radius: initial;
  height: 45px;
  padding: 8px 0 8px 0;
  line-height: 1.5;
}

.ng-select.ng-select-multiple.favorite-category-select>.ng-select-container>.ng-value-container>.ng-placeholder {
  position: initial;
}

.ng-select.ng-select-opened.favorite-category-select>.ng-select-container {
  border: 1px solid black;
  border-radius: initial;
  line-height: 1.5;
}

.ng-dropdown-panel.favorite-category-select {
  box-shadow: none;
  margin-left: -1px;
  min-width: 570px !important;
}

.ng-dropdown-panel.favorite-category-select>div.ng-dropdown-panel-items {
  font-size: 18px;
}

.ng-dropdown-panel.favorite-category-select>div.ng-dropdown-panel-items>div>div.ng-option {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.ng-dropdown-panel.favorite-category-select>div.ng-dropdown-panel-items>div>div.ng-option:last-child {
  border-radius: 0;
  border-right: 1px solid black;
  border-left: 1px solid black;
  border-bottom: 1px solid black;
}

button.favorite-icon {
  color: #069;
  background: #fff;
  font-size: 14px;
  padding: 0 2px 1px 2px;
  border: 0;
  border-radius: 3px;
  cursor: pointer;
  line-height: 1.5;
}

button.favorite-icon:hover {
  background-color: gainsboro;
}

h3.top10-changes {
  margin: 5px 0;
}

.changes-list h2 {
  display: inline-block;
}

div.changes-title {
  display: flex;
  flex-direction: row;
}

span.changes-list {
  margin-top: 35px;
}

.changes-list span {
  vertical-align: bottom;
  margin-left: 5px;
}

li.change-item {
  padding-bottom: 10px !important;
}

.change-item label {
  margin-bottom: 4px;
}

div.change-item-name {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-top: 6px;
}

p.change-item-date {
  font-size: 12px;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  padding: 6px 0;
  cursor: pointer;
  font-size: small;
}

div.thema-test {
  white-space: pre-wrap;
}

div.thema-test ul {
  padding-left: 17px;
}

div.thema-test li {
  list-style: disc;
}

div.thema-preview {
  margin-top: 30px;
}

ul.thema-preview {
  padding-left: 0;
}

ul.thema-preview li a {
  cursor: default !important;
}

hr.tab-end-thema {
  display: none;
}

.errors {
  margin-top: 27px;
  text-align: center;
}

div.errors h1 {
  padding-top: 30px;
  font-size: 3em;
}

.errors-input {
  border-color: red !important;
}

.errors-input div.ng-select-container {
  border-color: red !important;
}

.errors-text {
  color: red !important;
}

.event-datepicker input{
  border: none;
  width: 100% !important;
  font-size: 15px !important;
}

.form-element {
  margin-bottom: 13.5px;
}

.event-desc-tabs ul.tabmenu li {
  width: 10%;
}

.event-desc-tabs textarea {
  resize: vertical;
}

.subevent-add-button {
  color: #069;
}

.subevent-remove-button {
  color: #069;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.subevent {
  border: 2px solid #069;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

summary {
  color: #069;
  text-decoration: underline;
}

details[open] {
  padding: 10px 30px;
  border: 1px dashed black;
  border-radius: 5px;
  background-color: #f5f5f5;
}

details>p>img {
  width: 100%;
}

.awisa-footer {
  background-color: #f5f5f5;
  margin: 20px 0 0;
  padding: 12.5px 0 12.5px;
  border: 0;
  word-wrap: break-word;
}

.new-document-author {
  font-size: 16px;
  font-weight: normal;
}

/* Tooltip container */
.tooltip-awisa {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip text */
.tooltip-awisa .tooltiptext-awisa {
  visibility: hidden;
  width: 600px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text when mouse over the tooltip container */
.tooltip-awisa:hover .tooltiptext-awisa {
  visibility: visible;
  opacity: 1;
}

div.disclaimer-text {
  padding-top: 20px;
}

.clipboard-link-font {
  font-family: Arial, sans-serif;
  font-size: 11pt;
  font-weight: normal;
  text-decoration: underline;
}

a.adura-button{
  margin-top: 22px;
  color: black;
}

a.adura-button:hover {
  background-color: #f5f5f5;
  border-color: #069
}

.btn-space{
  margin-right: 5px;
  margin-bottom: 5px;
}

input.adura-form {
  font-size: inherit;
  height: 35px;
}

div.adura-form {
  height: 35px;
}

textarea.adura-form {
  font-size: 15px;
}

textarea.fixed-size {
  resize: none;
}

select.adura-form {
  width: 300px;
  height: 25px;
  padding: 0;
  font-size: 1em;
}

.ml-1 {
  margin-left: 1rem !important;
}

.tiny-input {
  border: 1px solid black;
  padding-top: 6px;
  padding-left: 12px;
  padding-bottom: 5px;
  padding-right: 12px;
  min-height: 35px;
}

.tiny-input p {
  margin-bottom: 0;
}

.tiny-input-settings {
  padding-top: 3px;
  padding-bottom: 3px;
  min-height: 30px;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid gainsboro;
}

.tiny-input, .tiny-input-settings p {
  margin-bottom: 0;
}

.tiny-textarea {
  border: 1px solid black;
  padding-top: 6px;
  padding-left: 12px;
  padding-bottom: 5px;
  padding-right: 12px;
  min-height: 200px;
}

.tiny-textarea p {
  margin-bottom: 0;
}

.tiny-input *[contentEditable="true"]:focus,
.tiny-input *[contentEditable="true"]:hover {
  outline: none;
}

.tiny-input-settings *[contentEditable="true"]:focus,
.tiny-input-settings *[contentEditable="true"]:hover {
  outline: none;
}

.tiny-textarea *[contentEditable="true"]:focus,
.tiny-textarea *[contentEditable="true"]:hover {
  outline: none;
  min-height: 180px;
}

.container .bullets li {
  list-style: disc;
}

.form-group .tiny-input li {
  list-style: disc;
}

.container .mce-content-body li {
  list-style: disc;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  z-index: 1700;
}

.tox-tinymce {
  margin-top: -6px;
  margin-left: -14px;
}

div.star-rating {
  margin-top: -7px;
  margin-left: -2px;
}

ul.adura-form {
  margin-left: -26px;
}

ul.adura-form li {
  list-style: disc;
}

h3#tracelog i {
  cursor: pointer;
}

div.adura-formular a {
  cursor: pointer;
}

.tox-tinymce-inline {
  z-index: 1600 !important;
}

div.adura-formular .ng-select-container {
  border: 1px solid black !important;
  border-radius: 0;
}

div.adura-formular .ng-placeholder {
  padding-top: 2px;
}

div.adura-formular .ng-dropdown-panel {
  border: 1px solid black;
  border-radius: 0;
}

.adura-signal-flag {
  color: #dc6565;
  font-size: 20px;
  line-height: 25px;
  margin: 0 10px 0 10px;
  float: right;

  position: relative;
  display: inline-block;
}

.adura-preview-tooltip {
  color: #069;
  font-size: 20px;
  line-height: 25px;
  margin: 0 10px 0 10px;
  float: right;

  position: relative;
  display: inline-block;
}

.adura-preview-tooltip .preview-tooltiptext {
  visibility: hidden;
  min-width: 300px;
  width: 100%;
  background-color: #f5f5f5;
  color: black;
  border-radius: 6px;
  padding: 2px 7px;
  font-size: 12px;
  font-family: "Frutiger Neue", Helvetica, Arial, sans-serif;

  /* Position the tooltip */
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 1;
}

.adura-preview-tooltip:hover .preview-tooltiptext {
  visibility: visible;
}

.preview-tooltiptext p {
  line-height: 20px;
  margin-bottom: 2px;
}

p.kurz-info {
  background-color: #f5f5f5;
  color: black;
  border-radius: 6px;
  padding: 2px 7px;
  font-size: 12px;
  font-family: "Frutiger Neue", Helvetica, Arial, sans-serif;
}

.adura-formular .form-control {
  height: 35px;
  padding: 8px 12px;
  font-size: 15px !important;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  background-image: none;
  border: 1px solid #333;
  border-radius: 0;
  box-shadow: none;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.adura-formular dp-date-picker.form-control {
  padding-left: 10px !important;
}

.adura-formular select.form-control {
  height: 35px !important;
}

.adura-formular select {
  padding: 8px 6px 7px !important;
}

.adura-formular .dp-input-container {
  top: -6px;
}

.adura-formular .html-select {
  height: 38px;
}

.adura-link {
  cursor: pointer;
}

label.adura-radio {
  font-weight: normal;
  padding-right: 10px;
}

label.steckbrief-document-radio {
  padding-top: 8px;
}

div.steckbrief-document {
  padding: 3px 3px 2px 0;
  margin-left: 0;
}

.table-no-borders th {
  border: none;
}

.table-no-borders td {
  border: none;
}

.modal-content .tiny-textarea {
  max-height: 45vh;
  overflow-y: auto;
}

div.adura-formular .alert {
  padding-left: 0;
  padding-bottom: 0;
}

div.adura-formular .alert p.adura-error {
  font-style: italic;
  text-align: center;
  margin-bottom: 13.5px;
}

div.adura-formular .form-group {
  padding-bottom: 3px;
}

div.adura-formular .bereiche {
  font-weight: normal;
}

div.adura-formular h2.facet-header {
  font-size: 20px;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-ellipsis p {
  display: inline;
}

div.adura-formular .filter-checkbox-label {
  flex-grow: 1;
  justify-content: flex-start;
  margin-right: 0.6em;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  overflow: hidden;
  font-size: 15px !important;
  padding-top: 2px;
}

h3.facet-subtitle {
  font-size: 15px;
  margin-top: 10px;
}

div.beurteilung-aktivitaet-list {
  padding-top: 5px;
  /*padding-bottom: 3px;*/
}

div.beurteilung-aktivitaet-list p {
  margin-bottom: 5px;
}

div.beurteilung-aktivitaet-list p:last-of-type {
  margin-bottom: 0;
}

.length-counter {
  position: absolute;
  top: -18px;
  right: 20px;
  display: block;
  font-size: 0.8em;
  color: #757575;
}

.floating-label {
  position: absolute;
  top: 15px;
  right: 20px;
  display: block;
  font-size: 0.8em;
  color: #757575;
}

.length-counter-modal {
  float: right;
  position: relative;
  top: 5px;
  display: block;
  font-size: 0.8em;
  color: #757575;
}

.mt-20 {
  margin-top: 20px;
}

app-rating-config {
  font-size: 25px;
}

hr {
  border-top: 1px solid #757575 !important;
}

.dropzone.dz-wrapper .dz-message {
  width: 100% !important;
  min-width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  max-height: 60px !important;
  height: 60px;
}

.dropzone-element {
  margin-top: 20px;
  margin-bottom: 20px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

p.filter-tag.active {
  margin-right: 3px;
  background-color: #ccc;
}

span.visually-hidden {
  display: none;
}
