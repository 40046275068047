@font-face {
  font-family: 'Frutiger Neue';
  src: url(frutiger/FrutigerNeueW02-Bd.woff2?v2) format("woff2"), url(frutiger/FrutigerNeueW02-Bd.woff) format("woff");
  font-weight: 700;
  font-style: normal
}

@font-face {
  font-family: 'Frutiger Neue';
  src: url(frutiger/FrutigerNeueW02-It.woff) format("woff");
  font-weight: 400;
  font-style: italic
}

@font-face {
  font-family: 'Frutiger Neue';
  src: url(frutiger/FrutigerNeueW02-Regular.woff2) format("woff2"), url(frutiger/FrutigerNeueW02-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: 'Frutiger Neue';
  src: url(frutiger/FrutigerNeueW02-Light.woff2) format("woff2"), url(frutiger/FrutigerNeueW02-Light.woff) format("woff");
  font-weight: 100;
  font-style: normal
}

